import { LOCAL_FUNCTION_API, WEB_FUNCTION_API, BACKEND_API } from "utils/consts/backendFunctionsConsts";
import axios from 'axios';

const isDevelopment = process.env.NODE_ENV !== null && process.env.NODE_ENV === "development";

const post = (route, data, isLocal = false) => {
    const url = `${_getApiRoute(isLocal)}${route}`;
    console.log(data);
    return axios.post(url, data);
}

const get = (route, isLocal = false) =>
{
    const url = `${_getApiRoute(isLocal)}${route}`;
    const headers = {
        'Content-Type' : 'multipart/form-data'
    };
    const config = {
        headers: headers,
        crossDomain: true,
        mode: 'cors'
    };
    return axios.get(url, config);
}

const request = (route, config, isLocal= false) =>
{
    config.url = `${_getApiRoute(isLocal)}${route}`;
    return axios.request(config);
}

function _getApiRoute(isLocal)
{
    if(isDevelopment) 
    {
       // return LOCAL_FUNCTION_API;
    }
    return BACKEND_API;
}

const functionRequester = {post: post, get: get, request: request};

export default functionRequester;