import React, {useState} from "react";
import {Stepper, Grid, Box, Link, Button, Typography} from '@mui/material';
import PrimaryInfoStep from "components/register/PrimaryInfoStep";
import InterestsInfoStep from "components/register/InterestsInfoStep";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import LinearProgress from '@mui/material/LinearProgress';
import {UserSignUp} from "api/userApi";
import 'assets/css/signup.css';
import{ArrowBack} from "@mui/icons-material";

const PHASE = {
    FILLING_FORM: 0,
    SUBMITTING_FORM: 1,
    SUBMITTED: 2
}
Object.freeze(PHASE);

export default function SignUp()
{
    function submitForm()
    {
        if(!submitValid)
        {
            return;
        }
        setSubmitValid(false);
        setCurrentPhase(PHASE.SUBMITTING_FORM);
        var signUpPromise = UserSignUp({...PrimaryInfoStep.values, ...InterestsInfoStep.values});
        signUpPromise.then(()=>{
            setCurrentPhase(PHASE.SUBMITTED);
        }).catch((signUpError) => {
            if(signUpError.code == 'ERROR_EMAIL_ALREADY_IN_USE')
            {
            }
        });
    }

    function handleSubmitPressed(e)
    {
        e.preventDefault();
        if(!steps[stepIndex].isValid)
        {
            return;
        }
        if(stepIndex + 1 >= steps.length)
        {
            submitForm();
        }
        else
        {
            setSubmitValid(false);
            setStepIndex(stepIndex + 1);
            setCurrentStep(steps[stepIndex + 1]);
        }
    }

    function handleBackPressed()
    {
        if(stepIndex > 0)
        {
            setSubmitValid(true);
            setStepIndex(stepIndex - 1);
            setCurrentStep(steps[stepIndex - 1]);
        }
    }

    const steps = [InterestsInfoStep, PrimaryInfoStep]
    const [currentStep, setCurrentStep] = useState(steps[0]);
    const [stepIndex, setStepIndex] = useState(0);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [email, setEmail] = useState('');
    const [submitValid, setSubmitValid] = useState(true);
    const [currentPhase, setCurrentPhase] = useState(PHASE.FILLING_FORM);
    const handlers = {
        setUsername: setUsername,
        setPassword: setPassword,
        setPasswordConfirm: setPasswordConfirm,
        setEmail: setEmail,
        setSubmitValid: setSubmitValid
    };

    function drawForm()
    {
        return(<>
                <Stepper activeStep = {stepIndex} alternativeLabel sx ={{position: 'relative'}}>
                    {steps.map((step, index)=>{
                        return(
                            <Step color='secondary' key ={step.title}>
                                <StepLabel>{step.title}</StepLabel>
                            </Step>
                        )
                    })}
                </Stepper>
                <currentStep.form handlers={handlers}/>
                <Grid container spacing ={0} sx ={{marginTop:'20px'}}>
                    <Grid item xs ={5}>
                        {
                            stepIndex > 0?
                                (
                                    <Link onClick={handleBackPressed} align='left' underline='none' sx={{marginTop:'15px',height:'100%', fontFamily:'Poppins', fontWeight:'500',
                                        fontSize:'15px', lineHeight:'15px', color:'#929292'}}><ArrowBack sx={{position:'relative', top:'4.5px'}}/>Go back</Link>)
                                :
                                (<><Typography align='left' sx={{marginTop:'15px',height:'100%', fontFamily:'Poppins', fontWeight:'500',
                                    fontSize:'15px', lineHeight:'15px', color:'#929292'}}>Already have an account? <Link href='/login' sx={{fontWeight:'bold'}}>Log In</Link></Typography></>)
                        }
                    </Grid>
                    <Grid item xs ={2}/>
                    <Grid item xs = {3}>
                        <Button disabled ={!submitValid} variant ="contained" onClick ={handleSubmitPressed} sx={{borderRadius:'10px', minHeight:'47px', width: '100%',
                            familyFont:'Poppins', fontWeight:'600', fontSize:'16px', lineHeight:'27px'}}>{steps[stepIndex].nextButtonText}</Button>
                    </Grid>
                </Grid>
            </>
        );
    }

    function drawLoading()
    {
        return (<LinearProgress sx ={{marginTop:"20px"}}/>);
    }

    function drawSubmitted()
    {
        return (<Typography variant ="h6">Registration complete. Please check your email for confirmation.</Typography>);
    }

    function drawCurrentPhase()
    {
        if(currentPhase === PHASE.FILLING_FORM)
        {
            return drawForm();
        }
        else if(currentPhase === PHASE.SUBMITTING_FORM)
        {
            return(
                <>
                    {drawForm()}
                    {drawLoading()}
                </>);
        }
        else if(currentPhase === PHASE.SUBMITTED)
        {
            return drawSubmitted();
        }
    }

    return(
        <Grid sx={{marginTop:'100px'}} container spacing ={0}>
            <Grid item xs ={0} sm={3} />
            <Grid item xs={12} sm={6} >
                <Box sx={{backgroundColor:'white', borderRadius:'10px',
                    boxShadow:'0px 2px 20px rgba(0, 0, 0, 0.1)',
                    padding:'30px'}}>
                    <Typography color='primary' sx={{fontFamily:'Poppins',
                        fontWeight:'600', fontSize:'30px',
                        lineHeight:'45px',
                        paddingBottom:'28px'
                    }}>Sign Up</Typography>
                    {drawCurrentPhase()}
                </Box>
            </Grid>
            <Grid item xs ={0} sm={3}/>
        </Grid>
    );
}