import * as React from "react";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Container from "@mui/material/Container";
import Fade from "@mui/material/Fade";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import "assets/css/posts.css";
/* 
Most of this is stolen from here:
https://mui.com/material-ui/react-popper/#transitions
*/
export default function EmojiPopperContainer(props) {
    const content = props.content;
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((previousOpen) => !previousOpen);
    };
    
    const handleClickAway = () =>{
        setAnchorEl(null);
        setOpen(false);
    }

    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? "transition-popper" : undefined;

    return (
        <>
            <button
                aria-describedby={id}
                type="button"
                onClick={handleClick}
                style={{float:'left',
                    margin: 0,
                    padding:0,
                    backgroundColor: 'transparent',
                    border: "none",
                    display:'inline-block'}}
            >
                {content()}
            </button>

            <Popper id={id} open={open} anchorEl={anchorEl} transition>
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClickAway}>
                    <Fade {...TransitionProps} timeout={350}>
                            <Box>
                                <Box sx={{ 
                                    background:'white',
                                height:'27px',    
                                    borderRadius: "5px",}}>
                                    {props.categoryStack}
                                </Box>
                                <Box className="emoji-container" {...props.container}>
                                    {props.emojiGrid}
                                </Box>
                            </Box>
                    </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
}