import {Box, Typography} from '@mui/material';
import {useNavigate} from "react-router-dom";

export default function Home()
{
    const navigator = useNavigate();
    const imgSrc = 'https://blog.gouletpens.com/wp-content/uploads/2018/04/Chicago-Skyline-2-of-3-%C2%A9-Andrew-Banks-Illustration-2-800x800.jpg';
    return(
        <Box onClick={()=>navigator('/artcommunity')}
             sx={{height:'50px', width:'100%', marginTop:'65px', padding:'5px', paddingLeft:'30px',
            backgroundColor:'white'}}>
            <Box sx={{
                display:'inline-block', float:'left',
                width: '50px',
                height: '50px',
                position:'relative',
                borderRadius: '50%',
                background: `url(${imgSrc})`,
                backgroundSize: `cover`,
                backgroundPosition: 'center',
                bottom:'5px'
            }}/>
            <Box sx={{marginLeft:'60px', height:'100%'}}>
                <Typography color='primary' sx={{height:'100%', fontSize:'15px', lineHeight:'40px'}} align="left">/artcommunity</Typography>
            </Box>
        </Box>
    )
}