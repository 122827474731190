import VariationTheme2 from 'assets/themes/variation2';
const VariationTheme6 =  JSON.parse(JSON.stringify(VariationTheme2));

VariationTheme6.palette.primary.main='#000'
delete VariationTheme6.palette.primary.dark;
VariationTheme6.community.communityNameColor = '#E45251';
//VariationTheme6.header.bgColor = 'linear-gradient(to top right, #BA0226 70%, #FD6A43)';
VariationTheme6.header.bgColor = '#BA0226';


export default VariationTheme6;