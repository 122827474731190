import * as React from "react";
import CommunityViewBody from "components/community/CommunityViewBody";
import {useParams } from "react-router-dom";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {setSectionId} from "services/redux/actions/communityActions";

export default function ViewCommunity(props)
{
    const communityData = useSelector(state=>state.communityData);

    useEffect(()=>{
        if(communityData.primary_info)
        {
            document.title = communityData.primary_info.title;
        }
    },[communityData]);
    
    const {community_handle} = useParams();
    return (
    <>
        <CommunityViewBody handle={community_handle} theme={props.theme}/>
    </>
    );
}