const ROUTE_PROTECTION_CHECKS ={
    SIGNED_IN: 0,
    MEMBER_IN_COMMUNITY:1,
}
Object.freeze(ROUTE_PROTECTION_CHECKS);

const ROUTE_PROTECTION_STATE ={
    ALLOWED: 0,
    PROCESSING: 1,
    NOT_ALLOWED: 2,
}
Object.freeze(ROUTE_PROTECTION_STATE);

export {ROUTE_PROTECTION_CHECKS, ROUTE_PROTECTION_STATE};