const COMMUNITY_INFO_CACHE_DURATION = 0;
const COMMUNITY_PRIMARY_INFO_CACHE_SUFFIX ="__PRIMARYINFO";
const COMMUNITY_ROLES ={
    MEMBER:'MEMBER', NOT_MEMBER:'NOT_MEMBER', MODERATOR:'MOD', ADMIN: 'ADMIN'
}
Object.freeze(COMMUNITY_ROLES);
const POST_CONTAINER_CACHE_KEY = "POST_CONTAINER_CACHE_KEY";
const POST_CONTAINER_CACHE_DURATION = 0;
const POST_NOT_FOUND = 'POST_NOT_FOUND';

export {POST_CONTAINER_CACHE_KEY, POST_CONTAINER_CACHE_DURATION,
    COMMUNITY_INFO_CACHE_DURATION,COMMUNITY_PRIMARY_INFO_CACHE_SUFFIX,
    COMMUNITY_ROLES, POST_NOT_FOUND};