import {SET_USER_INFO, SET_NOTIFICATIONS_OPEN, SET_USER_DRAWER_OPEN, SET_REDIRECT_PATH} from "../reduxConsts";
import {USER_INFO_STATE} from "utils/consts/userConsts";

const initialState ={
    redirect: {shouldRedirect: false, path:''},
    userDrawerOpen: false,
    notificationsOpen: false,
    user: {state: USER_INFO_STATE.LOADING, info:null},  
}

function systemReducer(state = initialState, action) 
{
    if(action.type === SET_REDIRECT_PATH)
    {
        return Object.assign({}, state, action.payload);
    }
    else if(action.type === SET_USER_DRAWER_OPEN)
    {
        return Object.assign({}, state, action.payload);
    }
    else if(action.type === SET_NOTIFICATIONS_OPEN)
    {
        return Object.assign({}, state, action.payload);
    }
    else if (action.type === SET_USER_INFO)
    {
        return Object.assign({}, state, action.payload);
    }
    return state;
}

export default systemReducer;