import {createStore, combineReducers} from 'redux';
import communityReducer from 'services/redux/reducers/communityReducer';
import postReducer from "services/redux/reducers/postReducer";
import systemReducer from "services/redux/reducers/systemReducer";

const rootReducer = combineReducers({
    communityData: communityReducer,
    postData: postReducer,
    systemData: systemReducer
});

export default createStore(rootReducer);

