import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import * as React from "react";
import {COMMUNITY_ROLES} from "utils/consts/communityConsts";
import {Box} from "@mui/material";
import {useEffect, useRef, useState} from "react";

export default function AddPostButton(props)
{
    const communityData = props.communityData;
    const [scroll, setScroll] = useState(0);
    const fabRef = useRef();
    var communityHeader = null;
    var btnHeight = null;
    
    useEffect(()=> {
        function handleScroll() {
            if(!communityHeader) 
            {
                communityHeader = document.getElementById('community-header');
            }
            if(!btnHeight)
            {
                btnHeight=fabRef.current.clientHeight;
            }
            if (window.scrollY === 0 || window.scrollY === 24) {
                return;
            }
            if(window.scrollY < communityHeader.clientHeight - btnHeight)
            {
                fabRef.current.style.top = 0;
                return;
            }
            fabRef.current.style.top = (window.scrollY - communityHeader.clientHeight + btnHeight*1.3)+'px';
        }

        window.addEventListener("scroll", handleScroll);

    }, []);
    if(!communityData.user_membership || communityData.user_membership === COMMUNITY_ROLES.NOT_MEMBER)
    {
        return <Box/>
    }
 return(
 <>
     <Fab ref={fabRef}  href={communityData.primary_info? `/${communityData.primary_info.handle}/post/create` : ''} sx={{position:'relative',
         margin:'0',
         display:{md:'none', lg:"inline-flex"},
         zIndex:'50',
         '&:hover':{
         opacity: 1
         },
         opacity:'0.3',
         transition:'top ease-in-out 0.5s',
         left:'50%'}} color="primary" aria-label="add">
     <AddIcon />
    </Fab>
 </>)   
}