const PREVIEW_IMG_HEIGHT = '420px';
const MAX_PREVIEW_WIDTH = '725px';

const MEDIA_PREVIEW_TYPES ={
    NONE: 0,
    IMAGE: 1,
    YOUTUBE: 2,
}
Object.freeze(MEDIA_PREVIEW_TYPES)

const TITLE_PLACEMENT ={
    BODY: 0,
    TOP: 1
}
Object.freeze(TITLE_PLACEMENT)

const AVATAR_SIZES = {
    XS: 40,
    SM: 50,
    MD: 60,
    LG: 70
}
Object.freeze(AVATAR_SIZES)

const MAX_PREVIEW_HEIGHT= 160;
const SECONDS_FOR_DOUBLE_CLICK = 0.25;

export {AVATAR_SIZES, TITLE_PLACEMENT, SECONDS_FOR_DOUBLE_CLICK, 
    MAX_PREVIEW_HEIGHT, PREVIEW_IMG_HEIGHT, MAX_PREVIEW_WIDTH, MEDIA_PREVIEW_TYPES};