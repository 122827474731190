import React, {useState,useEffect} from "react";
import logo from './logo.svg';
import './App.css';
import Home from 'views/home';
import Header from 'components/Header';
import DefaultTheme from 'assets/themes/default';
import VariationTheme2 from "assets/themes/variation2";
import VariationTheme3 from "assets/themes/variation3";
import VariationTheme4 from "assets/themes/variation4";
import VariationTheme5 from "assets/themes/variation5";
import VariationTheme6 from "assets/themes/variation6";
import BlackTheme from 'assets/themes/black';
import ViewCommunity from 'views/(community_handle)';
import {CreatePost} from "views/(community_handle)/post/create";
import ViewPost from "views/(community_handle)/post";
import {CommunityContainer} from "components/community/CommunityContainer";
import SignUp from 'views/signup';
import LogIn from "views/login";
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@emotion/react';
import {createTheme} from "@mui/material";
import {AuthUserProvider} from'services/firebase/AuthUserProvider';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {ProtectedRoute} from "components/system/ProtectedRoute";
import {Provider, useSelector} from 'react-redux';
import store from 'services/redux/store/index';
import {ROUTE_PROTECTION_CHECKS} from "utils/consts/routeConsts";
import Reset from "views/reset";

function App() {
    let [theme, setTheme] = useState(createTheme(VariationTheme2));
    const [themeAssigned, setThemeAssigned] = useState(false);

    return (
        <Provider store={store}>
            <ThemeSetter/>
            <ThemeProvider theme={theme}>
                <div className="App">
                    <AuthUserProvider>
                        <CssBaseline/>
                        <Header theme={theme}/>
                        <BrowserRouter>
                            <Routes>
                                <Route path='/:community_handle' element={<CommunityContainer
                                    theme={theme}
                                    communityElement={
                                        <ViewCommunity
                                            theme={theme}/>}/>}/>
                                <Route path='/:community_handle/post/create'
                                       element={<CommunityContainer theme={theme}
                                                                    checks={[ROUTE_PROTECTION_CHECKS.MEMBER_IN_COMMUNITY]}
                                                                    communityElement={<CreatePost
                                                                        theme={theme}/>}/>}/>
                                <Route path='/:community_handle/post'
                                       element={<CommunityContainer SEOPriority theme={theme} communityElement={<ViewPost
                                           theme={theme}/>}/>}/>
                                <Route path='/signup' element={<SignUp/>}/>
                                <Route path='/login' element={<LogIn/>}/>
                                <Route path='/reset' element={<Reset/>}/>
                                <Route path='' element={<Home/>}/>
                            </Routes>
                        </BrowserRouter>
                    </AuthUserProvider>
                </div>
            </ThemeProvider>
        </Provider>


    );
    function ThemeSetter()
    {
        const communityData = useSelector(state=>state.communityData);
        
        useEffect(() => {
            if (!themeAssigned && communityData.primary_info && communityData.primary_info.theme.theme_data) {
                    setThemeAssigned(true);
                    setTheme(createTheme(JSON.parse(communityData.primary_info.theme.theme_data)));
                }
        }, [communityData.primary_info]);
        
        return <></>
    }
}

export default App;