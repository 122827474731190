import useCommunityInfo from "services/hooks/community/useCommunityInfo";
import {useNavigate, useParams} from "react-router-dom";
import {LoadingSkeleton} from "../misc/LoadingSkeleton";
import CommunityHeader from "./CommunityHeader";
import * as React from "react";
import {useSelector} from "react-redux";
 import {ROUTE_PROTECTION_CHECKS, ROUTE_PROTECTION_STATE} from "utils/consts/routeConsts";
import {useState, useEffect} from "react";
import {Box} from "@mui/material";
import {USER_INFO_STATE} from "utils/consts/userConsts";
import {COMMUNITY_ROLES} from "utils/consts/communityConsts";

function CommunityContainer(props)
{
    const {community_handle} = useParams();
    const communityData = useSelector(state => state.communityData);
    const systemData = useSelector(state => state.systemData);
    const [routeState, setRouteState] = useState(ROUTE_PROTECTION_STATE.PROCESSING);
    const navigator = useNavigate();

    useCommunityInfo(community_handle);
    const checks = props.checks?? [];
    let checkFunctions = [];
    
    if(checks.length === 0 && ROUTE_PROTECTION_STATE === ROUTE_PROTECTION_STATE.PROCESSING)
    {
        setRouteState(ROUTE_PROTECTION_STATE.ALLOWED);
    }
    else 
    {
        if(checkFunctions.length === 0)
        {
            for(let i=0; i < checks.length; i++)
            {
                const check = checks[i];
                switch (check)
                {
                    case ROUTE_PROTECTION_CHECKS.SIGNED_IN:
                        checkFunctions.push({
                            check: ()=> systemData.user.state,
                            success: USER_INFO_STATE.LOGGED_IN,
                            fail: USER_INFO_STATE.LOGGED_OUT
                        });
                        break;
                    case ROUTE_PROTECTION_CHECKS.MEMBER_IN_COMMUNITY:
                        checkFunctions.push({
                            check: ()=> communityData.user_membership,
                            success: !COMMUNITY_ROLES.NOT_MEMBER,
                            fail: COMMUNITY_ROLES.NOT_MEMBER
                        });
                        break;
                }
            }
        }
    }
    
    useEffect(()=>{
        if(routeState !== ROUTE_PROTECTION_STATE.PROCESSING)
        {
            return;
        }
        let passedChecks = 0;
        for(let i=0; i < checkFunctions.length; i++)
        {
            const check = checkFunctions[i];
            const func = check.check();
            if(func)
            {
                if(func === check.passed)
                {
                    passedChecks++;
                }
                else if(func === check.fail)
                {
                    setRouteState(ROUTE_PROTECTION_STATE.NOT_ALLOWED);
                    return;
                }
            }
        }
        if(passedChecks === checks.length)
        {
            setRouteState(ROUTE_PROTECTION_STATE.ALLOWED);
        }
    }, [communityData, systemData]);
    
    if(routeState === ROUTE_PROTECTION_STATE.NOT_ALLOWED)
    {
        navigator('/login');
    }
    
    return(<>
        <LoadingSkeleton width="100%" height="100%"><CommunityHeader theme={props.theme}/></LoadingSkeleton>
        {props.communityElement} 
    </>)
}

export {CommunityContainer}