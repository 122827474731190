const grandTitleFont = 'Audiowide';
const font1 = 'Poppins';
const font2 = 'Cabin';

const DefaultTheme = {
    palette: {
        mode:'light',
        primary:{
            main:"#FD6A43",
            dark:"rgb(253, 135, 104)",
            contrastText:'white'
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: "rgb(240, 241, 244)",
                }
            }
        },
        MuiButton:{
            outlined: {
                '&:hover': {
                    backgroundColor: 'white'
                }
            }
        }
    },
    header:{
        bgColor: 'white',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
        logoVariant: 'h7',
        logoFontFamily: 'Audiowide',
        signInFont: 'Poppins',
        avatarBoxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
        avatarDefaultBackground: 'white',
        color: 'black'
    },
    fonts:{
        grandTitleFont: grandTitleFont,
        title1Font: font1,
        font2: font2
    },
    community:{
        defaultBannerImg: 'https://sketchfab.com/blogs/community/wp-content/uploads/2020/10/header-image-2-2048x683.jpg',
        bgHeight: '200',
        bgTopOffset:'13',
        bgObjectPosition:'top',
        communityAvatarBorderSize: 4,
        communityAvatarBorderColor: 'rgba(102, 102, 102, 0.63)',
        communityNameFont: 'Poppins',
        communityNameFontSize: 24,
        communityNameColor:'#FD6A43',
        communityHeaderBg:'white',
        communityHeaderBoxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
        communitySubNameFont: 'Open Sans',
        communitySubNameFontSize: 18,
        communitySubNameColor: 'rgba(102, 102, 102, 0.63)',
        topDividerColor: 'rgba(5, 5, 5, 0.29)',
    },
    segment:{
        titleColor: 'white',
        titleFont: 'Poppins',
        titleWeight: '600',
        titleSize: 18,
        boxShadow: 'none',//'0px 2px 4px rgba(0, 0, 0, 0.15)',
        borderSize: '10px',
        borderRadius: '10px 10px 0px 0px',
        bodyBg: 'rgba(240,240,240,0.9)',
        bodyTextColor: '#14213D',
        bodyTitleFont: 'Open Sans',
        bodyFont:'Cabin',
        bodyFontSize: 13,
        dividerColor: 'rgba(0, 0, 0, 0.12)',
        selectedSectionBGColor: 'white',//'rgba(68, 73, 80, 0.15)',
        selectedSectionColor: 'black',
        sectionHoverBgColor: 'rgba(255, 255, 255, 0.45)',//'rgba(0, 0, 0, 0.05)',
        joinColor: 'white',
        joinBGColor: 'rgba(0, 197, 43, 0.19)',
    },
    postContainer:{
        background: '#FFFFFF',
        boxShadow: '0px 7px 4px rgba(0, 0, 0, 0.15)',
    },
    post:{
        titleFont: 'Poppins',
        subtextFont: 'Cabin',
        bodyFont: 'Open Sans',
        bodyTextColor:'#313131',
        titleTextColor:'#14213D',
        usernameTextColor: '#494949',
        subtextTextColor: '#9F9D9D',
        authBgColor: 'rgba(0, 197, 43, 0.19)',
        authColor: '#00C52B',
        authFont: 'Cabin',
        sectionPreviewFont: 'Poppins',
        sectionPreviewColor:'#0568FC',
        sectionPreviewBGColor:'rgba(5, 104, 252, 0.23)',
    },
    createPost:{
        containerBoxShadow: '-8px 4px 23px 4px rgba(146, 146, 146, 0.15)',
    },
    search:{
        font:'Cabin',
        color:'#D3D3D3',
        backgroundColor:'rgba(240, 240, 240, 0.9)',
        boxShadow:'0px 2px 4px rgba(0, 0, 0, 0.25)',
        searchIconColor:'transparent'
    },
}; 


export default DefaultTheme;