import {Box, Alert, Snackbar, Button, Grid, Link, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import 'assets/css/signup.css';
import {UserLogIn} from "api/userApi";
import { useNavigate } from 'react-router-dom';

export default function LogIn() 
{
    const mdSpace = 3;
    const smSpace = 1;
    const xsSpace = 0;
    const mdContent = 12 - (mdSpace * 2);
    const smContent = 12 - (smSpace * 2);
    const xsContent = 12 - (xsSpace * 2);
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [canLogIn, setCanLogIn] = useState(true);
    const navigator = useNavigate();
    
    return(
        <Grid sx={{marginTop:'100px'}} container spacing ={0}>
            <Grid item xs ={xsSpace} sm={smSpace} md={mdSpace} />
            <Grid item xs={xsContent} sm={smContent} md={mdContent} >
            <Box sx={{backgroundColor:'white', borderRadius:'10px',
                boxShadow:'0px 2px 20px rgba(0, 0, 0, 0.1)',
                padding:'30px'}}>
                <Typography color='primary' sx={{fontFamily:'Poppins',
                    fontWeight:'600', fontSize:'30px',
                    lineHeight:'45px',
                    paddingBottom:'28px'
                }}>Log In</Typography>
            <Grid container spacing ={0}>
                <Grid item xs ={0} md={3} />
                <Grid item xs={12} md={6} >
                    <Typography color='error'>{error}</Typography>
                    <TextField onChange={handleEmailChanged} label="Email" type ="email" variant="outlined" sx ={{width: '100%'}} required/>
                    <TextField onChange={handlePasswordChanged} label="Password" type="password" variant="outlined" sx ={{width: '100%', marginTop:'10px'}} required/>
                    <Button variant ="contained" onClick ={handleSignInPressed} sx={{borderRadius:'10px', minHeight:'47px', width: '100%',
                        familyFont:'Poppins', fontWeight:'600', fontSize:'16px', lineHeight:'27px', marginTop:'10px'}}>Log In</Button>
                </Grid>
                <Grid item xs ={0} md={3}/>
            </Grid>
                <Typography sx={{marginTop:'15px', fontFamily:'Poppins', fontWeight:'500',
                    fontSize:'15px', lineHeight:'15px', color:'#929292'}}>Don't have an account? <Link href='/signup' align='left'>Sign Up</Link></Typography>
            </Box>
            </Grid>
        <Grid item xs ={0} md={3}/>
            <Snackbar open={showSuccess} autoHideDuration={1000}>
                <Alert severity="success" sx={{ width: '100%' }}>
                    Logged In Successfully
                </Alert>
            </Snackbar>
    </Grid>)
    
    function handleEmailChanged(e)
    {
        setEmail(e.target.value);
    }
    
    function handlePasswordChanged(e)
    {
        setPassword(e.target.value);
    }
    
    function handleSignInPressed()
    {
        if(!canLogIn)
        {
            return;
        }
        setError('');
        setCanLogIn(false);
        UserLogIn(email,password).then((UserCredential)=>{
            setShowSuccess(true);
            setTimeout(function(){
                navigator('/');
            }, 1000);
            
        }, (failedReason)=>{
            setCanLogIn(true)
            setError("Incorrect Email or Password");
        });
    }
}
