import * as React from "react";
import {useState} from "react";
import bannerImg from 'assets/img/DefaultCommunityBanner.png';
import communityAvatar from 'assets/img/DefaultCommunityAvatar.png';
import 'assets/css/CommunityTop.css';
import { Grid, Box, Link,Typography} from "@mui/material";
import {useSelector} from 'react-redux';

export default function CommunityHeader(props)
{
    let style = props.theme.community;
    const communityData = useSelector(state => state.communityData);
    const [scroll, setScroll]=useState(0);

    return (
    <Box id='community-header' sx={{width:'100%',position:'relative', zIndex:10,
        boxShadow:style.communityHeaderBoxShadow, backgroundColor:style.communityHeaderBg}}>
        <img src={style.defaultBannerImg} 
             height={style.bgHeight+'px'} width='100%' className='overflow-img' style={{
                 objectPosition:style.bgObjectPosition,
                 position: 'relative',
                 top: style.bgTopOffset +'px',
             }}/>
        <Grid container direction='row' height='90px'
         sx={{ marginBottom:'0px'}}>
            <Grid item xs={1}>
            </Grid>
            <Grid item xs={4} sm={3} md={2} lg={1.5}>
                <Grid container direction='row' position='relative'>
                    <Box className='community-avatar' 
                    sx={{
                        width:'160px',
                        height:'160px',
                        bottom:'-80px',
                        backgroundImage:`url(${communityData.primary_info? communityData.primary_info.avatar_url : ''})`,
                    backgroundSize:'contain',    
                    boxShadow:'0px 2px 4px rgba(0, 0, 0, 0.15)'}}>
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={7} marginTop='10px'>
                    <Typography
                    sx={{fontFamily:style.communityNameFont,
                        fontSize:style.communityNameFontSize,
                        color:style.communityNameColor,
                        fontWeight:'600',
                        filter:'drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.25)',
                        marginBottom: 0}}
                    align='left'>
                        <Link underline='none' sx={{color:style.communityNameColor, marginBottom:0}} href={communityData.primary_info? `/${communityData.id}`: ''}>
                        {communityData.primary_info && communityData.primary_info.title? communityData.primary_info.title : ""}
                        </Link>
                    </Typography>
                <Typography
                fontFamily={style.communitySubNameFont} 
                fontSize={style.communitySubNameFontSize}
                color={style.communitySubNameColor}
                fontWeight='400'
                sx={{filter:'drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.25)'}}
                align='left'>
                {communityData.id? "/"+communityData.id : "/"}</Typography>
            </Grid>
        </Grid>
    </Box>
    );
}