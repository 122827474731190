import {SET_POST_DATA, SET_POST_REACTIONS, SET_ALL_POSTS_REACTIONS} from "../reduxConsts";

const initialState ={
    post: null,
    reactions:{}
}

function postReducer(state = initialState, action) 
{
    if(action.type === SET_POST_DATA)
    {
        return Object.assign({}, state, action.payload);
    }
    else if(action.type === SET_POST_REACTIONS)
    {
        const newState = Object.assign({}, state);
        const payload = action.payload;
        newState.reactions[payload.post_id] = payload.reactions;
        return newState;
    }
    else if(action.type === SET_ALL_POSTS_REACTIONS)
    {
        const newState = Object.assign({}, state);
        const posts = action.payload.posts;
        for(const p of posts)
        {
            newState.reactions[p.id] = p.reactions;
        }
        return newState;
    }
    return state;
}

export default postReducer