import { Typography, Divider} from "@mui/material";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Box } from "@mui/system";
import * as React from 'react';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `0px solid ${theme.palette.divider}`,
    background: 'none',
    padding: '5px',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    flexDirection: 'row-reverse',
    background: "none",
    minHeight: '38px',
    padding: "0px",
    margin : '0px',
    '& .MuiAccordionSummary-content': {
        margin: '0px'
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    paddingTop: '0px'
}));

function ExpandableSegmentContainer({...props})
{
    let {theme, title, ...other} =props;
    const [expanded, setExpanded] = React.useState(true);
    
    const style = theme.segment;
    const padding = '10px';
    return ( <Box sx={{
        boxShadow: style.boxShadow,
        borderSize: style.borderSize,
        backgroundColor:style.bodyBg,
        width: '100%',
        borderRadius: style.borderRadius
    }}
          {...other}>
        <Box sx={{bgcolor: style.bodyBg,
            borderSize: style.borderSize,
            padding:padding}}>
            <Accordion expanded={expanded} onChange={()=>setExpanded(!expanded)}>
                <AccordionSummary>
                    <Typography align='left' sx={{fontSize: 15, fontFamily:style.titleFont, 
                        fontWeight:style.titleWeight, marginLeft:'5px'}}>{title??''}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {props.children}
                </AccordionDetails>
            </Accordion>
        </Box>
    </Box>)
}

ExpandableSegmentContainer.Separator = function(props){
    return (<Divider sx={{color: props.color, marginTop:'10px', marginBottom:'10px'}}/>)
}

export default ExpandableSegmentContainer;