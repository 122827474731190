import { Typography, Skeleton} from "@mui/material";
import SegmentContainer from "./SegmentContainer";
import JoinButton from "./JoinButton";
import {useSelector} from 'react-redux';

export default function AboutSegment(props)
{
    let style = props.theme.segment;
    let theme = props.theme;
    
    const communityData = useSelector(state => state.communityData);
    return (
        <SegmentContainer sx={{marginTop:'20px', width:'80%'}} theme={props.theme}
                          loadingWidth ={350} loadingHeight={230}>
            <Typography 
            align='left'
            sx={{
                color: style.bodyTextColor,
                fontFamily: style.bodyTitleFont,
                fontSize: style.titleSize,
                fontWeight: style.titleWeight,
            }}
            >Description</Typography>
            <Typography 
            align='left'
            sx={{
                color: style.bodyTextColor,
                fontFamily: style.bodyFont,
                fontSize: style.bodyFontSize,
            }}>
             {communityData.primary_info && communityData.primary_info.description?communityData.primary_info.description : ' '}
             </Typography>
             <SegmentContainer.Separator/>
            <JoinButton style={style}/>
        </SegmentContainer>
    )
}