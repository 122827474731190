import { Typography, Divider, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import * as React from 'react';

function SegmentContainer({...props})
{
    const isLoading = true;
    let {theme, title, ...other} =props;
    const titleHeight = '52px';
    const style = theme.segment;
    const padding = '10px';
    return ( <Box sx={{
        boxShadow: style.boxShadow,
        borderSize: style.borderSize,
        backgroundColor:style.bodyBg,
        width: '100%',
        borderRadius: style.borderRadius
    }}
          {...other}>
        <Box sx={{
            borderSize: style.borderSize,
            }}>
            <Typography align='left' sx={{fontSize: style.titleSize, fontFamily:style.titleFont,
            fontWeight:style.titleWeight}}>{title??''}</Typography>
            {props.children}
        </Box>
    </Box>)
}

SegmentContainer.Separator = function(props){
    return (<Divider sx={{color: props.color, marginTop:'10px', marginBottom:'10px'}}/>)
}

export default SegmentContainer;