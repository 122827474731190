import {Link, Box, Divider, Typography} from "@mui/material";
import PostPreview from "./PostPreview";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React from "react";
import {AVATAR_SIZES} from "utils/consts/postPreviewConsts";

const DELAY_BETWEEN_SHOWING_POSTS = 500;


export default function CommentsContainer(props)
{
    const style = props.theme.post;
    const commentsCount = props.commentsCount?? 45;
    const comments = props.comments;
    const commentsDict ={};
    comments.forEach(c=>{
        const reply_to = c['reply_to'];
        if(!commentsDict.hasOwnProperty(reply_to))
        {
            commentsDict[reply_to] = [];
        }
        commentsDict[reply_to].push(c);
    });
    return(
        <Box sx={{width:'100%', marginBottom:'10px'}}>
            <Divider sx={{ margin:'8px 12px 8px', display:'block'}}/>
            {Array.isArray(commentsDict['post'])?
                 <Box sx={{paddingLeft:'10px'}}>{drawCommentsRecursive(commentsDict['post'], 0, props, commentsDict)}</Box>
            :<></>}
        </Box>
    )
}

function drawCommentsRecursive(repliesTo, level, props, commentsDict)
{
    if(!repliesTo.map)
    {
        return <></>
    }
    return repliesTo.map((comment,i)=>{
    return(<Box sx={{position:'relative'}}>
        <>
            {/*commentsDict.hasOwnProperty(comment.id) && comment.reply_to !=='post'? <DrawLine/>:<></>*/}
            <PostPreview key={i} level={level} drawDivider={false}
                     commentPreview ={props.preview} post={comment}
                     bottomSectionAddon={()=>drawShowRepliesLink(5)}
                     hiddenDuration={DELAY_BETWEEN_SHOWING_POSTS * i} theme={props.theme}
             />
        {commentsDict.hasOwnProperty(comment.id)? 
            drawCommentsRecursive(commentsDict[comment.id], level+1, props, commentsDict):<></>}
        </>
    </Box>)})
}

function DrawLine()
{
    return (<Box sx={{border:'1px solid black', position:'absolute', up:'0px', left:'0px', height:'200%' }}/>)
}

function drawShowRepliesLink(repliesCount)
{
    return(<Box/>)
    return(
        <Box>
            <Link sx={{float:'left', position:'relative', 
                left:'-6px', marginRight:'5px'}}><ArrowDropDownIcon sx={{float:'left'}} /><Link sx={{float:'left'}}>Show {repliesCount} replies</Link></Link>
        </Box>
    )
}