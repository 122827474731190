import {useDispatch, useSelector} from "react-redux";
import {useAuth} from "services/firebase/AuthUserProvider";
import {useEffect, useState} from "react";
import {setCommunityLoading} from "services/redux/actions/communityActions";
import {GetCommunityInfo} from 'api/communityApi';
import {useParams} from "react-router-dom";

export default function useCommunityInfo(handle)
{
    const {authUser, loadingUser} = useAuth();
    const dispatcher = useDispatch();
    const communityData = useSelector(state => state.communityData);
    const [infoLoaded, setInfoLoaded] = useState(false);
    
    useEffect(()=>{
        if(loadingUser)
        {
            return;
        }
        var getMembershipConfig = {getMembership: false};
        if(authUser)
        {
            getMembershipConfig = {getMembership: true, username: authUser.username};
        }
        if(!communityData.primary_info) {
            dispatcher(setCommunityLoading(true))
            GetCommunityInfo(handle, true, getMembershipConfig, dispatcher);
        }
    },[loadingUser]);

    useEffect(()=>{
        if(!infoLoaded && communityData.primary_info)
        {
            dispatcher(setCommunityLoading(false));
            setInfoLoaded(true);
        }
    },[communityData]);
    
    return infoLoaded;
}