import {Box, Divider, Popover, Typography} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import{setNotificationsOpen} from "services/redux/actions/systemActions";

export default function NotificationPopup(props)
{
    const btn = props.buttonRef;
    const dispatcher = useDispatch();
    const systemData = useSelector(state => state.systemData);
    const notifications = ['AvatarDude liked your comment', 'AvatarDude replied to your comment', 'AvatarDude liked your comment']
    return(
        <Popover
        open ={systemData.notificationsOpen}
        onClose={()=>dispatcher(setNotificationsOpen(false))}
        anchorEl = {btn.current}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        >
            <Box sx={{width:'200px', padding:'5px'}}>
                {notifications.map((notification,i)=>{
                    const drawDivider = i < notifications.length - 1;
                    return (<><Typography key={i}>{notification}</Typography>{drawDivider?<Divider/>:<Box/>}</>)
                })}
            </Box>
        </Popover>
    )
}