const HasNumber = (input) =>{
    return /\d/.test(input);
}

function TryParseJSONObject (jsonString){
    try {
        var o = JSON.parse(jsonString);
        if (o && typeof o === "object") {
            return o;
        }
    }
    catch (e) { }

    return false;
};

function isArabic(text) {
    var pattern = /[\u0600-\u06FF\u0750-\u077F]/;
    return pattern.test(text);
}

function lerp (start, end, t){
    return (1-t)*start+t*end
}

function doesStartWithArabic(text)
{
    const noWhitespaces = text.replace(/\s/g, "");
    if(noWhitespaces.length == 0)
    {
        return false;
    }
    return isArabic(noWhitespaces[0]);
}


const recursiveSearch = (obj, map = {}) => {
    Object.keys(obj).forEach(key => {
        let value = obj[key];
        if(typeof value === "object"){
            return recursiveSearch(obj[key], map);
        };
        value = String(value);
        let foundKeys = [];
        if(map.hasOwnProperty(value))
        {
            foundKeys = map[value];
        }
        foundKeys.push(key);
        map[value] = foundKeys;
    });
    return map;
};

export {lerp, HasNumber, TryParseJSONObject, isArabic, doesStartWithArabic, recursiveSearch}