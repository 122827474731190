import {Skeleton} from "@mui/material";
import {useSelector} from 'react-redux';
import * as React from "react";

function LoadingSkeleton({...props})
{
    const communityData = useSelector(state => state.communityData);
    const {width, height, ...other} = props;
    let loading = communityData.infoLoading? true : false;
    
    return loading? 
        (<Skeleton variant="wave" width={width} height={height} sx={{animationDuration: '0.5s', backgroundColor: 'grey.100', borderRadius:'20px'}}>{props.children}</Skeleton>):props.children
}

export {LoadingSkeleton}