import ExpandableSegmentContainer from "./ExpandableSegmentContainer";
import { Typography, Box, CircularProgress, Grid } from "@mui/material";
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from "react";
import 'assets/css/community.css';
import {setSectionId} from "services/redux/actions/communityActions";
import {styled} from "@mui/system";
import TagIcon from '@mui/icons-material/Tag';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export default function SectionsSegment(props)
{
    const dispatcher = useDispatch();
    let style = props.theme.segment;
    const communityData = useSelector(state => state.communityData);
    const [sections, setSections] = useState([]);
    const [scroll, setScroll] = useState(0);
    
    const sectionsLoaded = communityData.sections? false : true;
    
    useEffect(()=> {
        function handleScroll() {
            if (window.scrollY === 0 || window.scrollY === 24) {
                return;
            }
            setScroll(Math.round(window.scrollY));
        }

        window.addEventListener("scroll", handleScroll);
        
    }, []);
    
    useEffect(()=>{
        if(!sectionsLoaded && communityData && communityData.sections !== undefined)
        {
            setSections(communityData.sections);
        }
    },[sectionsLoaded]);

    const GridWithTransition = styled(Grid)(
        ({ theme }) => `
        transition: background-color 0.1s linear;
      &:not(.selected-section):hover{
            background-color: ${style.sectionHoverBgColor};
      }
    `,
    );
    
    function Section(props)
    {
        const [selected, setSelected] = useState(false);
        let id = props.id;
        
        useEffect(()=>{
            if(communityData.section_id === -1 || (id == communityData.section_id && selected)
                || (id != communityData.section_id && !selected))
            {
                return;
            }
            console.log(communityData.section_id);
            setSelected(id.toString() == communityData.section_id);
        }, [communityData.section_id]);

        let colorStyle =  selected?
            {
                color:  style.selectedSectionColor,
                fontSize: '14px',
            }
            :
            {
                color: style.titleTextColor,
                fontSize: '14px',
            };
        const gridBgColor = selected?{backgroundColor:style.selectedSectionBGColor}:{};
        return(<>
            <GridWithTransition className={selected?'selected-section':''}
                  onClick={()=>dispatcher(setSectionId(communityData.section_id == id? null : id))} container 
                                sx={{...gridBgColor,padding:'5px', paddingLeft:'15px', borderRadius:'8px'}}>
                <Grid item xs={12}>
                <Typography
                        align='left'
                        sx={colorStyle}>
                        {props.title}
                    </Typography>
                </Grid>
            </GridWithTransition>
        </>)
    }

    function drawSections()
    {
            return(
                <>
                    {
                        sections.map((section,i)=>{
                          return <Section key={i} id={section.id}
                                   title={section.title} 
                                   description={section.description}
                                   separator={(i < sections.length-1)}
                                   posts_count={section.posts_count}
                                   drawIcon
                          />
                        })
                    }
                </>
            )
    }
    
    function drawLoading()
    {
        return(<Box sx={{ display: 'flex' }}>
            <CircularProgress />
        </Box>)
    }
    
    let top = 0;
    let appbar = document.getElementById('appbar-header');
    let communityHeader = document.getElementById('community-header')
    if(appbar && communityHeader)
    {
        top = communityHeader.clientHeight - scroll;
        top = top > appbar.clientHeight? top : appbar.clientHeight;
    }
    return(
            <Box className='scroll-section' sx={{
                position:'sticky',
                top:top+'px',
                transition:'top ease-in-out 0.05s',
                maxHeight:'95vh', padding:'10px', paddingLeft:'0px',
                paddingRight:'0px',
                backgroundColor:style.bodyBg,
                width:'100%'}}>
                    <ExpandableSegmentContainer padding ='0px' theme ={props.theme} title="Boards">
                        {sections.length > 0? drawSections() : drawLoading()}
        </ExpandableSegmentContainer>
                </Box>
    
    );
}