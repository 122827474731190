import { useState, useEffect } from 'react'
import firebase from './';
import { getAuth } from '@firebase/auth';
import {GetUserInfo} from "api/userApi";
import {USER_INFO_STATE, USER_INFO_CACHE_DURATION, USER_INFO_CACHE_KEY} from "utils/consts/userConsts";
import {setUserInfo} from "services/redux/actions/systemActions";
import SessionCache from "services/SessionCache";
import {useDispatch} from "react-redux";

const formatAuthUser = (user, info) => ({
  uid: user.uid,
  email: user.email,
  username: user.displayName,
  avatar_url: info? info.avatar_url : null,
  usage_interest: info? info.usage_interest : null
});

export default function useFirebaseAuth() {
  const cachedInfo = SessionCache.GetItem(USER_INFO_CACHE_KEY);
  const [authUser, setAuthUser] = useState(cachedInfo? cachedInfo.data : null);
  const [loading, setLoading] = useState(true);
  const dispatcher = useDispatch();

  if(cachedInfo)
  {
    dispatcher(setUserInfo(USER_INFO_STATE.LOGGED_IN, cachedInfo.data));
  }
  
  const authStateChanged = async (authState) => {
    if (!authState) {
      setAuthUser(null)
      setLoading(false)
      SessionCache.DeleteItem(USER_INFO_CACHE_KEY);
      dispatcher(setUserInfo(USER_INFO_STATE.LOGGED_OUT, null));
      return;
    }

    setLoading(true);
    if(cachedInfo)
    {
      setLoading(false);
      if(!cachedInfo.expired)
      {
        return;
      }
    }
    
    const info = await GetUserInfo(authState.displayName);
    const formattedUser = formatAuthUser(authState, info);
    setAuthUser(formattedUser); 
    SessionCache.SetItem(USER_INFO_CACHE_KEY, formattedUser, USER_INFO_CACHE_DURATION);
    dispatcher(setUserInfo(USER_INFO_STATE.LOGGED_IN, formattedUser));
    if(loading) 
    {
      setLoading(false);
    }
  };

// listen for firebase state change
  useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged(authStateChanged);
    return () => unsubscribe();
  }, []);

  return {
    authUser,
    loading
  };
}