// community
const SET_COMMUNITY_INFO = "SET_COMMUNITY_INFO";
const SET_COMMUNITY_LOADING = "SET_COMMUNITY_LOADING";
const SET_POSTS_LOADING = "SET_POSTS_LOADING";
const SET_COMMUNITY_MEMBERSHIP = "SET_COMMUNITY_MEMBERSHIP";
const SET_NEW_POSTS = "SET_NEW_POSTS";
const SET_SECTION_ID = "SET_SECTION";
const UPDATE_POST ="UPDATE_POST";

// post
const SET_POST_DATA = "SET_POST_DATA";
const SET_POST_REACTIONS = "SET_POST_REACTIONS";
const SET_ALL_POSTS_REACTIONS =  "SET_ALL_POSTS_REACTIONS";

// system
const SET_REDIRECT_PATH = "SET_REDIRECT_PATH";
const SET_USER_INFO = "SET_USER_INFO";
const SET_USER_DRAWER_OPEN = "SET_USER_DRAWER_OPEN";
const SET_NOTIFICATIONS_OPEN = "SET_NOTIFICATIONS_OPEN";

export {SET_SECTION_ID, SET_USER_INFO, SET_NOTIFICATIONS_OPEN, SET_USER_DRAWER_OPEN, 
    SET_COMMUNITY_INFO, SET_COMMUNITY_LOADING,
    SET_POSTS_LOADING,SET_COMMUNITY_MEMBERSHIP,SET_NEW_POSTS,
    SET_POST_DATA, SET_REDIRECT_PATH, UPDATE_POST, SET_POST_REACTIONS, SET_ALL_POSTS_REACTIONS};