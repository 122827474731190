import {Drawer, List, ListItemText, ListItem, Box} from '@mui/material';
import {styled} from "@mui/styles";
import {useDispatch, useSelector} from "react-redux";
import {setUserDrawerOpen} from "services/redux/actions/systemActions";
import {UserSignOut} from "api/userApi";

const CustomDrawer = styled(Drawer)(
{
    '& .MuiDrawer-paper':{backgroundColor: '#14213D', color:'rgba(255,255,255,1)'},
    '& .MuiTypography-root': {fontFamily:'Poppins'}
});
export default function UserDrawer(props)
{
    const dispatcher = useDispatch();
    const systemData = useSelector(state => state.systemData);
    return(
        <CustomDrawer
        anchor ="right"
        open = {systemData.userDrawerOpen}
        onClose = {()=>dispatcher(setUserDrawerOpen(false))}
        >
            <Box sx={{width:250, height:'100%'}}
            role ="presentation"
            onClick ={()=>dispatcher(setUserDrawerOpen(false))}>
                <List>
                    <ListItem button>
                        <ListItemText primary="Profile"/>
                    </ListItem>
                    <ListItem button>
                        <ListItemText primary="Settings"/>
                    </ListItem>
                    <ListItem button onClick={()=>handleSignOutPressed()}>
                        <ListItemText primary="Sign Out"/>
                    </ListItem>
                </List>
            </Box>
        </CustomDrawer>
    )
    
    function handleSignOutPressed()
    {
          UserSignOut().then(()=>window.location.reload());
    }
}