import { SET_SECTION_ID, SET_COMMUNITY_INFO, SET_COMMUNITY_LOADING,
    SET_POSTS_LOADING, SET_COMMUNITY_MEMBERSHIP, SET_NEW_POSTS, UPDATE_POST} from "../reduxConsts";

const setCommunityInfo = (info)=>{
    return {type: SET_COMMUNITY_INFO, payload: info};
}

const setCommunityLoading = (isLoading) =>{
    return {type: SET_COMMUNITY_LOADING, payload: {infoLoading: isLoading}};
}

const setPostsLoading = (isLoading) =>{
    return {type: SET_POSTS_LOADING, payload: {postsLoading: isLoading}};
}

const setCommunityMembership = (membership) =>{
    return {type: SET_COMMUNITY_MEMBERSHIP, payload:{user_membership: membership}};
}

const setNewPosts = (posts, replace=true)=>{
    return{type: SET_NEW_POSTS, payload:{posts: posts === null || posts === 'null'? []:posts, replace: replace}};
}

const updatePost = (post) =>{
    return{type: UPDATE_POST, payload:{post:post}}
}

const setSectionId = (section_id)=>{
   return {type: SET_SECTION_ID, payload:{section_id: section_id}}; 
};

export {setSectionId, setCommunityInfo, setCommunityLoading, updatePost, setPostsLoading, setCommunityMembership, setNewPosts};