import imagePostSample from "../../assets/img/posts/post_image_sample.png";
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import React, {useEffect, useState} from "react";
import {GetRecentPosts} from "api/communityApi";
import {setNewPosts} from "services/redux/actions/communityActions";
import PostsContainer from './PostsContainer';
import {Box} from '@mui/material';
import Backdrop from "@mui/material/Backdrop";
import {CircularProgress} from "@mui/material";
function CommunityPostContainer(props)
{
    const dispatcher = useDispatch();
    const communityData = useSelector(state => state.communityData);
    const infoLoaded = communityData.primary_info? false : true;
    let [posts, setPosts] = useState(communityData.posts?? []);
    const [sectionId, setSectionId] = useState(null);
    const [section, setSection] = useState(null);
    const [replacingPosts, setReplacingPosts] = useState(true);
    const [postsNotFound, setPostsNotFound] = useState(false);
    const [drawBreadcrumbs, setDrawBreadcrumbs] = useState(false);
    
    useEffect(()=>{
        if(!infoLoaded && communityData && !communityData.infoLoading)
        {
            GetRecentPosts(dispatcher, communityData.primary_info.handle);
        }
    },[infoLoaded]);
    
    useEffect(()=>{
        if(communityData.section_id !== sectionId && communityData.section_id !== -1)
        {
            setReplacingPosts(true);
            GetRecentPosts(dispatcher, communityData.primary_info.handle, communityData.section_id);
            setSectionId(communityData.section_id);
            setSection(communityData.sections.find(s => s.id == communityData.section_id))
        }
    },[communityData.section_id]);
    
    useEffect(()=>{
        setReplacingPosts(false);
        const ids = posts.map(function(p){
           return p.id; 
        });
        for(const post of communityData.posts)
        {
            if(!(post.id in ids))
            {
                setPosts(communityData.posts);
                break;
            }
        }
        setPostsNotFound(communityData.triedFetchingPost && communityData.posts.length < 1);
        setDrawBreadcrumbs(communityData.section_id === null);
    },[communityData.posts]);

    useEffect(()=>{
        if(!replacingPosts)
        {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    },[replacingPosts]);
    
    return ( 
            <Box sx={{marginBottom:'70px'}}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={replacingPosts}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <PostsContainer notFound ={postsNotFound} drawBreadcrumbs ={drawBreadcrumbs}
                                communityData={communityData} theme={props.theme} 
                                height={props.height} posts={posts} section={section}/>
                </Box>
            )
}


export {CommunityPostContainer};