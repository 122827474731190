import {SET_USER_INFO, SET_NOTIFICATIONS_OPEN, 
    SET_USER_DRAWER_OPEN, SET_REDIRECT_PATH} from "../reduxConsts";

const setRedirectPath = (shouldRedirect ,path) =>{
    return {type: SET_REDIRECT_PATH, payload: {redirect: {shouldRedirect:shouldRedirect, path: path}}};
}

const setUserDrawerOpen = (open) =>{
    return{type: SET_USER_DRAWER_OPEN, payload: {userDrawerOpen: open}}
}

const setNotificationsOpen = (open) =>
{
    return{type: SET_NOTIFICATIONS_OPEN, payload: {notificationsOpen: open}};
}

const setUserInfo = (state, info)=>{
    return {type: SET_USER_INFO, payload: {user: {state: state, info: info}}}
}

export{setUserInfo, setNotificationsOpen ,setUserDrawerOpen, setRedirectPath}