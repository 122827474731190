import React, {useEffect} from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import {DoesUsernameExist, DoesEmailExist} from 'api/userApi';
import {HasNumber} from 'utils/helpers';

const values={
    username:'',
    password:'',
    passwordConfirm:'',
    email:''
};

const errors ={
    username:"",
    password:"",
    passwordConfirm:"",
    email:"",
}

const PrimaryInfoStep ={
    title: "Your information",
    form: PrimaryInfoForm,
    values: values,
    errors: errors,
    nextButtonText: "Sign Up",
    isValid: true,
} 

function PrimaryInfoForm({handlers})
{
    const handleUsernameBlur = async e => {
        if(e.target.value.length < 6)
        {
            return;
        }
        const usernameExists = await DoesUsernameExist(e.target.value);
        if(usernameExists)
        {
            errors.username = 'Username already exists';
        }
        else
        {
            errors.username ='';
        }
        values.username = e.target.value;
        handlers.setUsername(values.username);
    };

    const handlePasswordBlur = e => {
        if(e.target.value.length < 6)
        {
            errors.password = 'Password must be 6 or more characters';
        }
        else if(!HasNumber(e.target.value))
        {
            errors.password = 'Password must contain at least one number';
        }
        else
        {
            errors.password = '';
        }

        values.password = e.target.value;
        handlers.setPassword(values.password);
    };

    const handlePasswordConfirmBlur = e => {
        if(e.target.value != values.password)
        {
            errors.passwordConfirm = "Password doesn't match";
        }
        else
        {
            errors.passwordConfirm = "";
        }
        values.passwordConfirm = e.target.value;
        handlers.setPasswordConfirm(values.passwordConfirm);
    };

    const handleEmailBlur = async e => {
        if(e.target.value.length < 3)
        {
            return;
        }
        const emailExists = await DoesEmailExist(e.target.value);
        if(emailExists)
        {
            errors.email = 'Email already exists';
        }
        else
        {
            errors.email = '';
        }
        values.email = e.target.value;
        handlers.setEmail(values.email);
    };

    useEffect(() => {
        let valid = true;
        for (const errorType in errors)
        {
            if(errors[errorType].length > 0)
            {
                valid = false;
                break;
            }
        }
        Object.keys(values).map((value, index)=>{
          if(values[value].length == 0)
          {
              valid = false;
          }
        });
        handlers.setSubmitValid(valid);
        PrimaryInfoStep.isValid = valid;
    });

    return(
        <Grid container spacing = {2} sx ={{marginTop:"1px"}}>
            <Grid item xs={0} lg={4}/>
            <Grid item xs={12} lg={4}>
                <TextField error={errors.username.length > 0} helperText={errors.username}
                label="Username" onBlur={handleUsernameBlur} defaultValue={values.username} variant="outlined" sx ={{width: '100%'}} required/>
            </Grid>
            <Grid xs={0} lg={4}/>
            <Grid item xs={0} lg={2}/>
            <Grid item xs={12} lg ={4}>
                <TextField error={errors.password.length > 0} helperText={errors.password}
                label="Password" type ="password" onBlur={handlePasswordBlur} defaultValue={values.password} variant="outlined"  sx ={{width: '100%'}} required/>
            </Grid>
            <Grid item xs={12} lg ={4}>
                <TextField error={errors.passwordConfirm.length > 0} helperText={errors.passwordConfirm}
                label="Confirm Password" type ="password" onBlur={handlePasswordConfirmBlur} defaultValue={values.passwordConfirm} variant="outlined"  sx ={{width: '100%'}} required/>
            </Grid>
            <Grid item xs={0} lg={2}/>
            <Grid item xs={0} lg={2}/>
            <Grid item xs={12} lg={8}>
                <TextField error={errors.email.length > 0} helperText={errors.email}
                 label="Email" onBlur={handleEmailBlur} defaultValue={values.email} type ="email" variant="outlined" sx ={{width: '100%'}} required/>
            </Grid>
            <Grid item lg ={2}/>
        </Grid>
    )
}

export default PrimaryInfoStep;