import {useAuth} from 'services/firebase/AuthUserProvider'
import { Box } from '@mui/system';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {useEffect, useState} from "react";
import {COMMUNITY_ROLES} from "utils/consts/communityConsts";
import {RequestCommunityRoleUpdate} from "api/communityApi";
import {Button} from "@mui/material";

export default function JoinButton({...props})
{
    const {style, ...other} = props;
    const {authUser, loading} = useAuth();
    const communityData = useSelector(state => state.communityData);
    const dispatcher = useDispatch();
    const [buttonEnabled, setButtonEnabled] = useState(true);
    const [membership, setMembership] = useState(null);
    
    useEffect(() => {
        setMembership(communityData.user_membership);
    }, [communityData.user_membership]);

    if(loading || !authUser || !membership)
    {
        return(<></>);
    }

    return(<Box sx={{
        padding:'3px 7px',
        borderRadius: '5px',
        width:'30%',
        
    }}><Button variant='contained' color='primary'
     onClick={()=>handlePressed()}
     disabled={!buttonEnabled}
   sx={{color:style.joinColor}}
    >
        {membership === COMMUNITY_ROLES.NOT_MEMBER? '+Join' : 'Leave'}
    </Button>
    </Box>);

    function handlePressed()
    {
        const join = communityData.user_membership === COMMUNITY_ROLES.NOT_MEMBER;
        setButtonEnabled(false);
        RequestCommunityRoleUpdate(dispatcher, authUser.username,communityData.primary_info.handle, join).then(()=>{
            setButtonEnabled(true);
        });
    }
}