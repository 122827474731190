import {useParams } from "react-router-dom";
import {Grid, Breadcrumbs, Link, CircularProgress} from "@mui/material";
import React, {useEffect, useState} from "react";
import PostPreview from "components/posts/PostPreview";
import {useDispatch, useSelector} from "react-redux";
import {GetPost} from "api/communityApi";
import { useNavigate, useLocation } from 'react-router-dom';
import {Box} from "@mui/system";
import {LoadingSkeleton} from "components/misc/LoadingSkeleton";
import{TITLE_PLACEMENT} from "utils/consts/postPreviewConsts";
import Backdrop from "@mui/material/Backdrop";

export default function ViewPost(props)
{
    const navigate = useNavigate();
    const dispatcher = useDispatch();
    const postData = useSelector(state => state.postData);
    const communityData = useSelector(state=>state.communityData);
    const{community_handle} = useParams();
    const search = useLocation().search;  
    const post_id = new URLSearchParams(search).get('id');
   
    const [post, setPost] = useState(null);
    
    useEffect(()=>{
        GetPost(dispatcher,community_handle, post_id);
    }, []);
    
    useEffect(()=>{
        if(post || communityData.primary_info === null) {
            return;
        }
        if(!postData.post) 
        {
            return;
        }
        if(!postData.postFound)
        {
            navigate(`/${community_handle}`);
        }
        else if (postData.postFound) {
            setPost(postData.post);
            document.title = `${communityData.primary_info.title} - ${postData.post.title}`;
        }
        
    }, [postData, communityData]);

    if(!post_id)
    {
        navigate(`/${community_handle}`);
    }
    
    return(
      <>
          <Grid sx={{marginTop:'10px'}} container spacing ={0}>
              <Grid item xs ={0} lg={2} />
                  <Grid item xs ={12} lg = {8}>
                      <LoadingSkeleton width="100%" height="100%"> <Box sx={{background: props.theme.postContainer.background,
                          borderRadius: '10px',
                          padding:'25px',
                          width: '100%',
                          display: post === null ? 'none' :null,
                          boxShadow: props.theme.postContainer.boxShadow,
                          marginBottom:'30px',
                          minHeight:'200px'}}>
                          {
                              post?
                                  (<PostPreview titlePlacement={TITLE_PLACEMENT.BODY}
                                                breadcrumbs ={drawBreadcrumbs}
                                                hideCommentsIcon expandedByDefault fullPost
                                                communityData={communityData} drawDivider={false} post={post} 
                                                hiddenDuration={0} theme={props.theme}/>
                              ):  <Backdrop
                                      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                      open={true}
                                  >
                                      <CircularProgress color="inherit" />
                                  </Backdrop>
                          }
                      </Box></LoadingSkeleton>
                  </Grid>
                 <Grid item xs ={0} lg={2} />

              <Grid/>
          </Grid>
      </>  
    );
    
    function drawBreadcrumbs()
    {
        if(!post)
        {
            return(
                <Box/>
            );
        }
        return(<Breadcrumbs aria-label="breadcrumb">
            <Link underline='hover' href={'/'+communityData.id}>
                {communityData.primary_info.title}
            </Link>
            <Link underline='hover' href={'/'+communityData.id+'?section='+post.section_id}>
                {post.section.title}
            </Link>
        </Breadcrumbs>)
    }
}