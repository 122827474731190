import DefaultTheme from 'assets/themes/default';
const VariationTheme4 =  JSON.parse(JSON.stringify(DefaultTheme));

VariationTheme4.palette.primary.main='#2b2b3a'
delete VariationTheme4.palette.primary.dark;
VariationTheme4.palette.primary.contrastText = '#2b2b3a';
VariationTheme4.header.bgColor= '#1ed2f4';
VariationTheme4.header.color = '#f4f5f6';
VariationTheme4.community.communityHeaderBg = '#1ed2f4';
VariationTheme4.community.communityNameColor = '#f4f5f6';
VariationTheme4.postContainer.background = '#f4f5f6';
VariationTheme4.community.defaultBannerImg = 'https://www.clippergroup.co.uk/wp-content/uploads/2019/07/future-forum-header.jpg';



export default VariationTheme4;