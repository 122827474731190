import {Button,FormControl, InputLabel, Select, MenuItem, Grid, Box, Typography, Divider, TextField} from '@mui/material';
import TagsInput from "components/misc/TagsInput";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import * as React from "react";
import {useState} from "react";
import {LoadingSkeleton} from "components/misc/LoadingSkeleton";
import {useAuth} from "services/firebase/AuthUserProvider";
import {CreateNewPost} from "api/communityApi";

function CreatePostBody(props)
{
    const handle = props.handle;
    const navigator = useNavigate();
    const theme = props.theme;
    const {authUser, loading} = useAuth();
    const communityData = useSelector(state => state.communityData);
    const [section, setSection] = useState('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [enablePosting, setEnablePosting] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const foundSectionId = searchParams.get('section');
    const sectionDict = {};
    
    if(foundSectionId && section !== '')
    {
        setSection(foundSectionId);
    }
    
    function drawSectionsList()
    {
        sectionDict['']='...';
        const sections = communityData.sections;
        if(!sections || (sections && sections.length < 1))
        {
            return <MenuItem value={''}>...</MenuItem>
        }
        sections.forEach((s)=>{
            sectionDict[s.id] = s.title;
        });
        return(
                sections.map((section,i)=>{
                    return <MenuItem key={i} value={section.id}>{section.title}</MenuItem>
                })
        )    
    }

    function handleSubmit()
    {
        if(!communityData.primary_info || !authUser)
        {
            return;
        }
        setEnablePosting(false);
        const data = {
            handle: communityData.primary_info.handle,
            section_id: section,
            username: authUser.username,
            content: content,
            title: title,
        }
        createNewPostAsync(data);
    }
    
    const createNewPostAsync = async(data) =>
    {
       const post = await CreateNewPost(data);
       if(post.id)
       {
           navigator('/'+communityData.primary_info.handle+"/post?id="+post.id.toString());
       }
    }
    
    return(<>
    <Grid container direction='row' spacing ={0}>
        <Grid item xs={0} md ={2}>
        </Grid>
        <Grid item xs={12} md={8} sx={{marginBottom:'40px'}}>
            <LoadingSkeleton width='100%' height='100%'> 
                    <Box sx={{
                    background: props.theme.postContainer.background,
                    width:'100%',
                    minHeight:'300px',
                    borderRadius:'10px', 
                    boxShadow: theme.createPost.containerBoxShadow,
                    padding:'25px 20px'}}>
                        <Typography align='left' sx={{fontFamily: theme.fonts.title1Font,
                        color: theme.post.titleTextColor,
                        fontWeight: 500,
                        fontSize: '24px'}}>
                            Create Post
                        </Typography>
                        <Divider sx={{marginTop:'20px'}}/>
                        <FormControl sx={{display:'inline-block',width:'50%', float:'left'}}>
                        <InputLabel sx={{backgroundColor:'white'}} id="demo-simple-select-label">Board</InputLabel>
                        <Select
                            value={section}
                            sx={{width:'100%'}}
                            onChange={(event)=>{setSection(event.target.value)}}
                            labelId="demo-simple-select-label"  label="Board"
                            renderValue={(value)=> (<Typography align='left'>{sectionDict[value]}</Typography>)} 
                        >
                            <MenuItem value=''>...</MenuItem>
                            {drawSectionsList()}
                        </Select>
                        </FormControl>
                        {section !== ''?(<>
                        <TextField
                            onChange={(event)=>{setTitle(event.target.value)}}
                            sx={{fontFamily: theme.fonts.title1Font, fontWeight:800, color: theme.post.titleTextColor, width:'100%', marginTop:'20px', borderRadius:'10px'}} label="Title"/>
                        <TagsInput sx={{width:'100%', marginTop:'20px', borderRadius:'10px'}} onTagsUpdate={handleTagsUpdated} label="Tags"/>
                        <TextField
                            onChange={(event)=>{setContent(event.target.value)}}
                            multiline minRows={10} sx={{width:'100%', marginTop:'20px', borderRadius:'10px'}} label="Content"/>
                            <Button
                                onClick={()=>handleSubmit()}
                                color='primary'
                                variant='contained'
                                sx={{fontFamily:'Poppins', marginTop:'20px'}}>
                                Create Post
                            </Button>
                        </>):(<Box/>)}
                       
                        </Box>
            </LoadingSkeleton>
        </Grid>
        <Grid item xs={0} md ={2}>
        </Grid>
    </Grid>
    </>);
    
    function handleTagsUpdated(tags)
    {
        
    }
}

export {CreatePostBody}