import { getAuth, signInWithEmailAndPassword, signInWithCustomToken  } from "firebase/auth";
import {  collection, query, where, setDoc, doc, getDocs, getFirestore } from "firebase/firestore"; 
import functionRequester from 'utils/network/functionRequester';
import 'firebase/database';
import { useHistory } from "react-router-dom";

const UserSignUp = async (values)=>
{
  const auth = getAuth();
  if(auth.currentUser)
  {
    await auth.signOut();
  }
  if(values.interests)
  {
    values.interests = JSON.stringify(values.interests);
  }
  return functionRequester.post('user/create', values).then((res)=>{
    console.log("Signed up user")
   const data = res.data;
    console.log(data);
    if(data.token)
    {
      signInWithCustomToken(auth, data.token);
    }
    else{
    }
  })
}

const UserSignOut = async () =>
{
  const auth = getAuth();
  return auth.signOut();
}

const DoesUsernameExist = async (username) =>
{
  return false;
  const db = getFirestore();
  const usersRef = collection(db, 'users');
  const q = query(usersRef, where('username', '==', username));
  const snapshot = await getDocs(q);
  if(snapshot.empty)
  {
    return false;
  }
  else
  {
    return true;
  }
}

const DoesEmailExist = async (email) =>
{
  return false;
  const db = getFirestore();
  const emailRef = collection(db, 'user_emails');
  const q = query(emailRef, where('email', '==', email));
  const snapshot = await getDocs(q);
  if(snapshot.empty)
  {
    return false;
  }
  else
  {
    return true;
  }
}


async function GetUserInfo(username){
  try{
    const res = await functionRequester.get('user/get-info/'+username);
    console.log(res)
    return res.data;
  }
  catch(e)
  {
    console.log(e)
  }
}

const UserLogIn = async(email, password) => {
  try{
    const auth = getAuth();
    return signInWithEmailAndPassword(auth, email, password);
  }
  catch(e)
  {
  }
}

export {UserLogIn, UserSignOut, GetUserInfo, UserSignUp, DoesUsernameExist, DoesEmailExist};