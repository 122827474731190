import { createTheme } from "@mui/material/styles";

const BlackTheme = createTheme({
    palette:{
    background: {
        default: "#080808"
        }
    },
    header:{
        bgColor: '#080808',
        boxShadow: 'none',
        logoVariant: 'h7',
        logoFontFamily: 'Audiowide',
    },
    community:{
        communityAvatarBorderSize: 4,
        communityAvatarBorderColor: '#C4C4C4',
        communityNameFont: 'Poppins',
        communityNameFontSize: 24,
        communityNameColor:'#FFFFFF',
        communitySubNameFont: 'Open Sans',
        communitySubNameFontSize: 18,
        communitySubNameColor: 'rgba(255, 255, 255, 0.63)',
        topDividerColor: '#C4C4C4',
    },
    segment:{
        titleBg: '#0568FC',
        titleColor: 'white',
        titleFont: 'Poppins',
        titleWeight: '600',
        titleSize: 18,
        boxShadow:'0px 2px 10px rgba(0, 0, 0, 0.15)',
        borderSize: '10px',
        borderRadius: '10px 10px 0px 0px',
        bodyBg: 'white',
        bodyTextColor: '#14213D',
        bodyTitleFont: 'Open Sans',
        bodyFont:'Cabin',
        bodyFontSize: 13,
        dividerColor: 'rgba(0, 0, 0, 0.12)',
    },
    postContainer:{
        background: '#FFFFFF',
        boxShadow:'0px 2px 10px rgba(0, 0, 0, 0.15)'
    },
    post:{
        titleFont: 'Poppins',
        subtextFont: 'Cabin',
        bodyFont: 'Open Sans',
        bodyTextColor:'#313131',
        titleTextColor:'#14213D',
        usernameTextColor: '#494949',
        subtextTextColor: '#9F9D9D'
    }
}); 


export default BlackTheme;