import emojisBySlug from 'assets/emojis/emojis-by-slug.json';
import Grid from "@mui/material/Grid";
import 'assets/css/posts.css';
import {Box, Button} from "@mui/material";
import {sortBy} from "utils/sortBy";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";

const ROW_HEIGHT = 30;
const CELL_WIDTH = 35;
const EMOJIS_PER_ROW = 12.0;

function ReactionButton(props)
{
    const emoji = props.emoji;
    const handleReact = props.handleReact;
    return(
        <Box className="emoji-reaction-static" sx={{borderRadius:'5px', textAlign:'center', justifyContent:'center'}}
        onClick={()=>handleReact(props.postId, emoji)}>
            <span>{emoji.emoji}</span>
            <span style={{ position: "relative", top: "2px", right: "2px", fontSize:10 }}><b>{emoji.count}</b></span>
        </Box>
    )
}

export default function PostReactions(props)
{
    let reactionData ={};
    const postData = useSelector(state => state.postData);
    const [reactions, setReactions] = useState([]);
    useEffect(()=>{
        if(props.post.id in postData.reactions)
        {
            reactionData = {};
            setReactions(postData.reactions[props.post.id]);
        }
        },[postData]);
    if(reactions.length < 1)
    {
        return <></>
    }
    const list = reactions.sort(sortBy('slug', false, a => a.toUpperCase()));
    const handleReact = props.handleReact;
    for(const r of list)
    {
        if(r.slug in reactionData)
        {
            reactionData[r.slug].count++;
        }
        else
        {
            reactionData[r.slug] = {emoji: emojisBySlug[r.slug].emoji, slug: r.slug, count:1};
        }
    }
    return Object.keys(reactionData).map(function(k,i){
        return(
            <Grid key={k} item xs={0.7} sx={{height:ROW_HEIGHT, padding:'4px'}}>
                <ReactionButton postId={props.postId} handleReact={handleReact} emoji={reactionData[k]}/>
            </Grid>
        )
    });
}