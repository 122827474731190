import * as React from "react";
import {Box, Grid} from "@mui/material";
import AboutSegment from "./AboutSegment";
import SectionsSegment from "./SectionsSegment";
import {useDispatch, useSelector} from 'react-redux';
import {LoadingSkeleton} from'components/misc/LoadingSkeleton';
import {CommunityPostContainer} from "components/posts/CommunityPostContainer";
import AddPostButton from "components/community/AddPostButton";
import {useSearchParams} from "react-router-dom";
import {setSectionId} from "services/redux/actions/communityActions";
import {useEffect} from "react";

export default function CommunityViewBody(props)
{
    const communityData = useSelector(state => state.communityData);
    let [searchParams, setSearchParams] = useSearchParams();
    const dispatcher = useDispatch();
    const sectionIdParam = searchParams.get('section');

    const loading = communityData.infoLoading;
    
    useEffect(()=>{
        if(sectionIdParam && !communityData.section_id)
        {
            dispatcher(setSectionId(sectionIdParam.toString()));
        }    
    }, []);
    
    return(
        <>
        <Grid container direction='row' spacing ={0}>
            <Grid item sx={{display:{md:'none', lg:'unset'}}}
                  xs={0} lg={2.3}>
                <LoadingSkeleton width='100%' height='100%'><SectionsSegment theme={props.theme}/></LoadingSkeleton>
            </Grid>
           <Grid  lg={0.2}/>
            <Grid item md={12} lg={6.7}>
                <Box sx={{position:'relative', height:0}}>
                   <AddPostButton communityData={communityData}/>
                </Box>
                <LoadingSkeleton width='100%' height='100%'><CommunityPostContainer height={500}  theme ={props.theme}/></LoadingSkeleton>
            </Grid>
            <Grid  lg={0.2}/>
            <Grid item md={0} lg={2.5}>
                <LoadingSkeleton width='100%' height='100%'><AboutSegment theme={props.theme}/></LoadingSkeleton>
            </Grid>
        </Grid>
        </>
    );
}