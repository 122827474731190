import { Box } from "@mui/system";
import PostPreview from "./PostPreview";
import CommentsContainer from "./CommentsContainer";
import {TITLE_PLACEMENT} from "utils/consts/postPreviewConsts";
import {Breadcrumbs, Link, Typography} from "@mui/material";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import SegmentContainer from "components/community/SegmentContainer";
import {ReactToPost} from "api/communityApi";
import {useAuth} from "services/firebase/AuthUserProvider";
const DELAY_BETWEEN_SHOWING_POSTS = 500;

export default function PostsContainer(props)
{
    const style = props.theme.postContainer;
    const {authUser, loadingUser} = useAuth();
    const dispatcher = useDispatch();
    const communityData = useSelector(state=>state.communityData);
    let notFound = props.notFound;
    let posts = props.posts;
    const shouldDrawBreadcrumbs = props.drawBreadcrumbs;
    
    return(
        <>
            {props.section?
                (<><SegmentContainer sx={{
                    align:'center',
                    backgroundColor: 'rgba(255,255,255,0.5)',
                    marginTop: '10px',
                    padding:'20px',
                    paddingLeft:'25px',
                    borderRadius: '10px',
                    fontFamily:"Roboto"
                    }} theme={props.theme}
                                   loadingWidth ={350} loadingHeight={230} title ={props.section.title}>
                    <Typography sx={{fontFamily:"Roboto"}} align='left'>{props.section.description}</Typography>
                </SegmentContainer>
                </>) : (<></>)}
            <Box sx={{
            width: '100%',
           }}>
                {
                notFound? (<Typography>No posts found</Typography>)
                   : 
                   (posts.map((post,i)=>{
                   const drawDivider = i > 0;
                   let renderComments = false;
                   if(post.comments && post.comments.length > 0)
                   {
                       renderComments = true;
                   }
                    return <>
                        <PostPreview key={i} titlePlacement={TITLE_PLACEMENT.BODY} renderComments={renderComments}
                                     communityData={props.communityData} drawDivider={drawDivider}
                                      breadcrumbs={shouldDrawBreadcrumbs? ()=>drawBreadcrumbs(post) : false}
                                      post={post} hiddenDuration={DELAY_BETWEEN_SHOWING_POSTS * i} theme={props.theme}
                                      handleReact = {handleReact}
                                      commentsContainer = {renderComments? ()=> <CommentsContainer preview theme={props.theme} commentsCount={post.replies_count} comments ={post.comments}/> : ()=> <></>}
                        />
                    </>
                }))
            }
            </Box>
        </>
    );

    function handleReact(postId, emoji)
    {
        const post = communityData.posts.find(p => p.id === postId);
        ReactToPost(dispatcher, emoji.slug, authUser.username, post, communityData.primary_info.handle, true);
    }
    
    function drawBreadcrumbs(post)
    {
        let header = props.theme.header;
        if(!post)
        {
            return(
                <Box/>
            );
        }
        return(<Breadcrumbs sx={{ fontSize:'0.8rem', fontWeight:'500'}} aria-label="breadcrumb">
            {<Link sx={{color: header.bgColor}} underline='hover' href={'/'+communityData.id}>
                {communityData.primary_info.title}
            </Link>}
            <Link  sx={{color: header.bgColor}} underline='hover' href={'/'+communityData.id+'?section='+post.section_id}>
                {post.section.title}
            </Link>
        </Breadcrumbs>)
    }
}