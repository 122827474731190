import React, {useState} from 'react';
import { Grid, Typography, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import TagsInput from 'components/misc/TagsInput';

const values={
    interests:[],
    usageInterest: "users"
};

const InterestsInfoStep ={
    title: "Interests",
    form: InterestsInfoForm,
    values: values,
    nextButtonText: "Next",
    isValid: true,
} 

function InterestsInfoForm({handlers})
{
    function handleInterestsTagChange(items) {
        values.interests = items;
      }
    
    function handleUsageInterestChange(e)
    {
        values.usageInterest = e.target.value;
    }

    return(
        <Grid container spacing = {2} sx ={{marginTop:"50px"}}>
            <Grid item xs={12}>
                <Typography align='left' sx={{
                    display:'inline-block', float:'left',
                    familyFont:'Poppins',
                    fontWeight:'600',
                fontSize:'16px', lineHeight:'27px'}}>
                    1.Tell us about your interests 
                </Typography>
                <Typography sx={{display:'inline-block', float:'left',
                    familyFont:'Poppins',
                    fontWeight:'500',
                    fontSize:'16px', 
                    lineHeight:'27px',
                    color:'#A6A6A6'}}>
                    &nbsp;(optional)
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TagsInput
                label = "Interests" 
                onTagsUpdate={handleInterestsTagChange}
                placeholder = {values.interests.length === 0 ? (""):("")}
                tags = {values.interests}
                sx = {{width: '100%'}}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography align='left' sx={{
                    display:'inline-block', float:'left',
                    familyFont:'Poppins',
                    fontWeight:'600',
                    fontSize:'16px', lineHeight:'27px'}}>
                    2.Which aspect excites you more about using our platform?
                </Typography>
                <Typography sx={{display:'inline-block', float:'left',
                    familyFont:'Poppins', fontWeight:'500', fontSize:'16px', lineHeight:'27px',
                    color:'#A6A6A6'}}>
                    &nbsp;(optional)
                </Typography>
            </Grid>
            <Grid item xs = {12}>
                <RadioGroup defaultValue = {values.usageInterest}>
                    <FormControlLabel value="users" onChange={handleUsageInterestChange} control={<Radio />} label="Connecting with more users over shared interests" />
                    <FormControlLabel value="content" onChange={handleUsageInterestChange} control={<Radio />} label="Browsing conent relevant to your interests" />
                </RadioGroup>
            </Grid>
        </Grid>
    );
}

export default InterestsInfoStep;