import * as React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { useState } from "react";
import EmojiPopperContainer from "./EmojiPopperContainer";
import emojisByGroup from 'assets/emojis/emojis-by-group.json';
import emojiGroupInfo from 'assets/emojis/group-info.json';
import Stack from"@mui/material/Stack";
import 'assets/css/posts.css';

const ROW_HEIGHT = 30;
const CELL_WIDTH = 35;
const EMOJIS_PER_ROW = 12.0;
const EMOJI_DISPLAY_COUNT = 50;

function EmojiItems(props) {
    const currentGroup = emojisByGroup["Smileys & Emotion"];
    const items = [];
    const length = Math.min()
    for (let i = 0; i < currentGroup.length; i++) {
        const emoji = currentGroup[i];
        if(emoji.emoji.length > 4)
        {
           continue;
        }
        items.push(
            <Grid item key={i} xs={1} sx={{ height: ROW_HEIGHT }}>
                <Box className="emoji-grid-item" sx={{borderRadius:'5px', textAlign:'center', justifyContent:'center'}} 
                     onClick={() => props.handleReact(props.postId, emoji)}>
                    <span>{emoji.emoji}</span>
                </Box>
            </Grid>
        );
    }
    return items;
}

function EmojiCategories(props)
{
    return (<Stack sx={{height:'100%', alignContent:'flex-end'}} direction='row' spacing={0}>{emojiGroupInfo.map((e, i)=> {
        return(<span key={i} style={{width:CELL_WIDTH, textAlign:'center', justifyContent:'center'}} className="emoji-group-grid-item">{e.icon}</span>)   
    })}
    </Stack>)
}

function EmojiGrid(props) {
    const handleReact = props.handleReact;
    return (
        <Box
            sx={{
                width: props.width,
                background:'white',
            }}
        >
            <Grid container sx={{overflow:"hidden"}}>
                {/*Inside our grid container, we just use the EmojiItems component*/}
                <EmojiItems postId={props.postId} handleReact={handleReact}/>
            </Grid>
        </Box>
    );
}

export default function EmojiWindow(props)
{
    const rowCount = Math.ceil(EMOJI_DISPLAY_COUNT / EMOJIS_PER_ROW);
    const height = ROW_HEIGHT * rowCount + 10;
    const width = EMOJIS_PER_ROW * CELL_WIDTH +10;
    const emojiGrid = (<EmojiGrid width = {width} height={height} postId={props.postId} handleReact={props.handleReact} />);
    const categoryStack = (<EmojiCategories/>);
    
    return (
        <EmojiPopperContainer categoryStack={categoryStack} emojiGrid={emojiGrid} container={{sx:
                {width: width, 
                height:height, 
                padding:'0px',
                background:'white', 
                overflowY:'scroll', 
                boxShadow: "0px 0px 3px rgba(0,0,0,0.6)",
                borderRadius: "5px",}}}{...props}>
            
        </EmojiPopperContainer>
    );
}