import {SET_POST_DATA, SET_POST_REACTIONS, SET_ALL_POSTS_REACTIONS} from "../reduxConsts";
import{POST_NOT_FOUND} from "utils/consts/communityConsts";

const setPostData = (data) =>{
    return {type: SET_POST_DATA, payload: data};
}

const setPostReactions = (post_id, reactions) =>{
    return{type: SET_POST_REACTIONS, payload: {post_id: post_id, reactions: reactions}};
}

const setAllPostsReactions = (posts) =>{
    return{type: SET_ALL_POSTS_REACTIONS, payload: {posts: posts}}
}

export{setPostData, setPostReactions, setAllPostsReactions}