import { Box } from '@mui/system';
import {Typography, Grid, Button, Fade, Link, Slide, Grow, Divider} from '@mui/material';
import 'assets/css/posts.css';
import commentIcon from 'assets/img/icons/comment-icon.svg';
import postIcon from 'assets/img/icons/post-icon.svg';
import happyIcon from 'assets/img/icons/happy-icon.svg';
import sadIcon from 'assets/img/icons/sad-icon.svg';
import shareIcon from 'assets/img/icons/share-icon.svg';
import {TITLE_PLACEMENT, SECONDS_FOR_DOUBLE_CLICK, MAX_PREVIEW_HEIGHT, PREVIEW_IMG_HEIGHT as PREVIEW_CONTENT_HEIGHT, MEDIA_PREVIEW_TYPES } from 'utils/consts/postPreviewConsts';
import {COMMUNITY_ROLES} from "utils/consts/communityConsts";
import EmojiWindow from "../misc/EmojiWindow"
import PostReactions from "./PostReactions";

import YoutubeEmbed from './YoutubeEmbed';
import getYoutubeId from 'utils/getYoutubeId';
import getImageLink from 'utils/getImageLink';
import React, {useEffect, useRef, useState} from "react";

export default function PostPreview(props)
{
    const [imgLink, setImgLink] = useState('');
    const style = props.theme.post;
    const postContainerStyle = props.theme.postContainer;
    const commentsContainer = props.commentsContainer;
    const post = props.post;
    const title = props.title?? '';
    const img = post.poster.avatar_url;
    let level = props.level===null? 0 : props.level;
    const hiddenDuration = props.hiddenDuration;
    const fullPost = props.fullPost && props.fullPost === true? true : false;
    const commentPreview = props.commentPreview && props.commentPreview === true? true : false;
    let comment = commentPreview;
    const expandedByDefault = props.expandedByDefault && props.expandedByDefault === true? true: false;
    let hideCommentsIcon = props.hideCommentsIcon && props.hideCommentsIcon === true? true: false;
    hideCommentsIcon = comment || commentPreview? true : hideCommentsIcon;
    let hideShareButton = props.hideShareButton && props.hideShareButton === true? true: false;
    hideShareButton = comment || commentPreview? true : hideShareButton;
    const breadcrumbs = props.breadcrumbs;
    const handleReact = props.handleReact;
    const displaySectionName = false;
    const communityData = props.communityData;
    let titleFontSizeValue = 1.34;
    let bodyFontSizeValue = 0.9;
    bodyFontSizeValue = commentPreview? 0.85 : bodyFontSizeValue;
    let multiplier = 1.0;
    let avatarHeight = 50;
    avatarHeight = fullPost? 70 : avatarHeight;
    avatarHeight = commentPreview? 45 : avatarHeight;
    if(avatarHeight === 60)
    {
        multiplier = 1.0;
    }
    if(avatarHeight === 50)
    {
        multiplier = 0.95;
    }
    let bodyFontSize = bodyFontSizeValue * multiplier + 'rem';
    let titleFontSize = titleFontSizeValue * multiplier + 'rem';
    let avatarHeightNum = avatarHeight;
    let contentMarginLeft = '0px';
    contentMarginLeft = commentPreview? avatarHeight + 11 +'px' : contentMarginLeft;
    let drawBottomSection = true;
    const drawDivider = props.drawDivider;
    const renderComments = props.renderComments && props.renderComments === true? true: false;
    const postContentDivId = `${post.id}-content-container`;
    const [canFadeIn, setCanFadeIn] = useState(false);
    const [isLongPost, setIsLongPost] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    let lastClickDate = new Date();

    useEffect(()=>{
        setTimeout(function(){
            setCanFadeIn(true);
            const element = document.getElementById(postContentDivId);
            if(element === null)
            {
                return;
            }
            if(document.getElementById(postContentDivId).clientHeight > MAX_PREVIEW_HEIGHT)
            {
                setIsLongPost(true);
            }
        }, hiddenDuration);
    }, []);
    
    if(post.poster.role === COMMUNITY_ROLES.MODERATOR)
    {
        post.auth_degree =  post.poster.role.toString().toLowerCase();
        if(post.auth_degree === "mod")
        {
            post.auth_degree = "Moderator"
        }
    }
    
    if(!canFadeIn)
    {
        return <Box> </Box>
    }
    
    function handleExpandClicked(e)
    {
        if(!isLongPost || expandedByDefault)
        {
            return;
        }
        const now = new Date();
        const duration = (now.getTime() - lastClickDate.getTime())/1000.0;
        if(!isExpanded)
        {
            if(duration > SECONDS_FOR_DOUBLE_CLICK)
            {
                setIsExpanded(true);
            }
        }
        else 
        {
            
            if(duration < SECONDS_FOR_DOUBLE_CLICK)
            {
               // setIsExpanded(false);
            }
        }
        lastClickDate = now;
    }

    const expandedStyle = isLongPost && !isExpanded && !expandedByDefault? {maxHeight:'160px', overflow:'hidden'} : {};

    return(
        <Box className="post-container-parent" sx={{
            background: comment || fullPost? 'none' : postContainerStyle.background,
            borderRadius: '10px',
            padding:'25px',
            paddingTop: comment || fullPost? '0px' : '15px',
            width: '100%',
            boxShadow: comment || fullPost? 'none':'1px 1px 2px rgba(0, 0, 0, 0.15)',
            marginTop: comment || fullPost? '0px' :'16px',
            paddingLeft: (level * avatarHeightNum)+'px', paddingBottom: comment? '0px':'10px'}}>
        <Grow in={canFadeIn} timeout={{ enter: 700, exit:700 }} easing={{ enter: 'cubic-bezier(0,0.55,.61,1.2)', 
            exit: 'cubic-bezier(0,-0.55,.61,1)' }} sx={{ position:'relative', animationDuration:'4s'}}>
        <Box>
            {breadcrumbs? breadcrumbs() : (<></>)}
             <Grid container sx={{marginTop:'10px'}}>
                <Grid item xs={fullPost? 9.5 : 10}>
                    
                <Grid container spacing={1} direction='row' sx={{height: avatarHeight, marginBottom: comment? 0 :10 * multiplier  + 'px'}}>
                    <Grid item>
                        <img
                            src={post.poster.avatar_url}
                            style={{
                                marginRight: multiplier +'px',
                              width: avatarHeight + 'px', 
                                height: avatarHeight + 'px',
                                borderRadius:'50%',
                                objectFit:'cover',
                                objectPosition: 'center',
                                boxShadow:'rgba(0, 0, 0, 0.15) 0px 2px 2px'
                            }}
                        />
                        {/*<Box sx={{
                            width: avatarHeight+'px',
                            height: avatarHeight+'px',
                            position:'relative',
                            borderRadius: '50%',
                            background: `url(${post.poster.avatar_url})`,
                            backgroundSize: `cover`,
                            backgroundPosition: 'center',
                            border:'thick',
                            bottom:'5px'
                        }}/>*/}
                        
                    </Grid>
                    <Grid item sx={{height:'20px'}}>
                        <Grid container direction='column'>
                            <Grid item xs={10}>
                                <Typography
                                align='left'
                                fontSize = {multiplier +'rem'}
                                fontFamily={style.bodyFont} 
                                color={style.usernameTextColor}
                                fontWeight ={600}   
                                >@{post.poster.username} 
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{marginLeft:'6px'}}>
                                <Box sx={{display:'flex', height:'16px', width:'65px', justifyContent:'space-around', alignItems:'center'}} container direciton = 'row'>
                                    <IconCounter 
                                        fontFamily={style.subtextFont} 
                                        color={style.subtextTextColor}
                                        fontWeight ={400}
                                        icon = {postIcon}
                                        counter ={post.poster.posts_count}   
                                        iconStyle={{height: '15px', width:'16px'}}
                                        />
                                    <IconCounter
                                        iconStyle ={{position:'relative', top:'1px', height:'16px'}}
                                    fontFamily={style.subtextFont} 
                                    color={style.subtextTextColor}
                                    fontWeight ={400}
                                    icon = {commentIcon}
                                    counter ={post.poster.comments_count}    
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    {post.auth_degree? (<Grid item><AuthDegree style={style} auth_degree={post.auth_degree}/></Grid>):(<></>)}
                    <Grid item>
                        <TimeSincePost timeSince ="5 hours" style={style}/>
                    </Grid>
                 </Grid>
                </Grid>
                 {displaySectionName? (<Grid item xs ={2}><SectionName style={style} post={post}/></Grid>):(<></>)}
             </Grid>
                {props.titlePlacement !== TITLE_PLACEMENT.TOP? (<Box/>) :
                   <Typography align='left' sx={{
                       fontFamily: style.titleFont,
                       color: style.titleTextColor,
                       fontWeight:'600',
                       fontSize: titleFontSize,
                       lineHeight: titleFontSize,
                       width:'100%',
                       height:'100%',
                       marginBottom: commentPreview? '4px' : '10px'
                   }}>
                       {title}
                   </Typography>}

                <Box sx={{marginTop:'3px', marginLeft: contentMarginLeft}}>
                        {props.titlePlacement !== TITLE_PLACEMENT.BODY? (<Box/>) :
                    <Typography align='left'>
                        <Link sx={{
                            display:'inline-block',
                            width: '90%',
                            fontFamily: style.titleFont,
                            color: style.titleTextColor,
                            fontWeight:'600',
                            fontSize: titleFontSize,
                            lineHeight: titleFontSize,
                        }} underline='none' href={`/${communityData? communityData.primary_info.handle : ''}/post?id=${post.id}`}>{post.title}</Link>
                    </Typography>}
                    <Box onClick={(e)=>handleExpandClicked(e)} sx={{...expandedStyle}}
                         className={isLongPost && !isExpanded && !expandedByDefault? 'cover-container' : ''}>
                        <Box sx={{whiteSpace:'pre-wrap', maxWidth:'95%'}} id={postContentDivId}>
                            <div style={{
                                fontFamily: style.bodyFont,
                                color: style.bodyTextColor,
                                fontWeight:'400',
                                fontSize: bodyFontSize,
                                paddingTop: commentPreview? '5px' : '0px'
                            }}
                                align='left'><DrawContent/></div>
                        </Box>
                    </Box>
                    <PreviewMediaContent post={post} style={style}/>
                    <BottomSection bottomSectionAddon ={props.bottomSectionAddon?? false} style={style}/>
                    {commentsContainer? commentsContainer() : <></>}
                </Box>
        </Box>
            
        </Grow>
            
        </Box>
    );
    
    function SectionName(props)
    {
        const post = props.post;
        const style = props.style;
        return(<>
            <Typography align='center' sx={{
                marginBottom: 0,
             fontSize: '13px',
             fontFamily:style.sectionPreviewFont,
             color:style.sectionPreviewColor,
            fontWeight:800}}>
             {post.section.title}
            </Typography>
        </>);
    }

    function DrawContent(drawProps)
    {
        const fragments = post.content.split(/\s|\n/g);
        const spaces = post.content.match(/\s|\n/g);
        return fragments.map(function(f,i){
            
            const space = i > spaces.length-1? '' : spaces[i];
           if(f.includes('http'))
           {
               return (<a key={i} href={f}>{f+space}</a>)
           }
           return (<span key={i}>{f+space}</span>)
        });
    }
    
    function drawReactionButton(props)
    {
        return (
            <div className='happy-icon-parent'>
                <Box className='happy-icon'
                     sx={{float:'left', display:'inline-block', maskImage:`url(${happyIcon})`,
                         bgcolor:style.subtextTextColor}}/>
                <span style={{ position: "relative", top: "3px", right: "3px" }}>+</span>
            </div>
        )
    }
    function IconCounter({...props})
    {
        const {icon, counter, iconStyle, typographyStyle, ...other} = props;

        return(<Box sx={{display:'flex', justifyContent:'flex-start', alignItems:'center',
            fontSize:'0.8rem', flexBasis:'27px', marginRight:'10px', ...typographyStyle}} {...other}>
            <img src={icon} style={{...iconStyle, width:'15px', marginRight:'3px'}}/> 
            <p>{counter}</p>
        </Box>)
    }

    function AuthDegree(props)
    {
        const style = props.style;
        const degree = props.auth_degree;
        return(<><Box sx={{
            position: 'relative',
            top: '2px',
            height:'20px',
            padding:'3px 7px',
            borderRadius: '5px',
            backgroundColor: style.authBgColor}}>
                <Typography sx={{
                 fontSize: '14px',
                 lineHeight: '14px',
                 fontFamily:style.authFont,
                 color:style.authColor}}>
                    {degree}
                </Typography>
            </Box>
            </>);
    }

    function TimeSincePost(props)
    {
        const style = props.style;
        const timeSince = props.timeSince;
        return(<><Box sx={{position:'relative', top:'2px'}}>
                <Typography sx={{
                 fontSize: '14px',
                 fontFamily:style.subtextFont,
                 color:style.subtextTextColor}}>
                    {timeSince}
                </Typography>
            </Box>
            <br style={{clear:'both'}} />
            </>);
    }

    function BottomSection(props)
    {
        const style = props.style;
        return(
        <>
            <Grid direction='row' container spacing={0}>
                <EmojiWindow handleReact={handleReact} postId={post.id} content={drawReactionButton}/>
                <PostReactions post={post} handleReact={handleReact} postId={post.id}/>
            </Grid>
        <Grid container direction='row' sx={{marginTop:commentPreview? '3px' : '3px', width:'100%'}}>
            {props.bottomSectionAddon? props.bottomSectionAddon() : <></>}
            {hideCommentsIcon? (<Box/>):
            <Grid item className='comments-button' sx={{  display:'inline-block'}}>
                <Typography textAlign='left' sx={{ display:'inline-block', float:'left', color: props.style.subtextTextColor, fontFamily: props.style.subtextFont}}>
                <Box className='comment-button-icon' sx={{maskImage:`url(${commentIcon})`,
             backgroundColor:props.style.subtextTextColor, marginRight:'5px'}}> </Box>
                Comment
                </Typography>
            </Grid>}
            <Grid item>
            {hideShareButton?(<></>):(
                <Box className='share-button' sx={{float:'left', display:'inline-block'}}>
                <Typography sx={{color: props.style.subtextTextColor, fontFamily: props.style.subtextFont}}>
                <Box className='share-button-icon' sx={{maskImage:`url(${shareIcon})`,
             backgroundColor:props.style.subtextTextColor, marginRight:'7px', marginLeft:'6px'}}> </Box>
                Share
                </Typography>
            </Box>)}
            </Grid>
        </Grid>
        </>
        );
    }

    function PreviewMediaContent(props)
    {
        const [width, setWidth] = useState(0);
        const [height, setHeight] = useState(0);
        const post = props.post;
        const style = props.style;
        const img = getImageLink(post.content);
        let youtube = getYoutubeId(post.content);
        let previewType = MEDIA_PREVIEW_TYPES.img;
        let preview = (<></>)
        if(img)
        {
            preview = (<Box sx={{width: '93%', 
            height: PREVIEW_CONTENT_HEIGHT,
            background:`url(${img})`,
            marginTop: '20px',
                backgroundSize: 'cover'
            }}/>);
        }
        else if(youtube) {
            const containers = document.getElementsByClassName('post-container-parent');
            if (containers.length > 0) 
            {
                const calculatedWidth = containers[0].clientWidth * (0.92);
                if(width != calculatedWidth) {
                    setWidth(calculatedWidth);
                    let newHeight = calculatedWidth < 700? calculatedWidth * 0.75 : calculatedWidth * (9.0/16.0);
                    setHeight(newHeight);
                }
                console.log(width);
                preview = (<YoutubeEmbed embedId={youtube}
                                         width={width.toString()+'px'} 
                                         height={height.toString()+'px'}
                                         style={{marginTop: '20px'}}/>);
            } 
            else 
            {
                preview = (<YoutubeEmbed embedId={youtube} width='93%' height={PREVIEW_CONTENT_HEIGHT}
                                         style={{marginTop: '20px'}}/>);
            }
        }
        return(preview);
    }

}