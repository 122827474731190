import * as React from 'react';
import {InputBase} from "@mui/material";
import { styled } from '@mui/system';
import searchIcon from "assets/img/icons/search.svg";

import {Box} from "@mui/material";

export default function SearchInput(props) {
    const style = props.theme.search;
    const StyledInputElement = styled('input')(
        ({ theme }) => `
      width: 100%;
      height: 35px;
      font-size: 15px;
      font-family: ${style.font};
      font-weight: 400;
  line-height: 18px;
      background: ${style.backgroundColor};
      box-shadow: ${style.boxShadow};
      border-radius: 5px;
      border-style: none;
      padding: 10px 8px;
      transition: all 150ms ease;
      
      &::placeholder{
            color: ${style.color};
      }
    `,
        );
    return(
      <Box sx={{width:'100%', height:'35px', background: style.backgroundColor, boxShadow: style.boxShadow, borderRadius:'5px', 
      borderStyle: 'none', padding:'0px 0px', overflow:'none'}}>
          <InputBase placeholder="Search..." sx={{ width:'87%', height:'100%', 
          background:style.backgroundColor, borderStyle:'none',
          fontSize:'15px', fontFamily: style.font, fontWeight:400,
          lineHeight: '18px', border:'0px'}}/>
          <Box style={{position:'relative', display:'inline-block', float:'right', width:'10%', 
              height:'20px', top:'7px',
              maskImage:`url(${searchIcon})`,
              backgroundColor:style.searchIconColor, maskSize:'contain', maskRepeat:'no-repeat', maskPosition:'center'}} />
      </Box>  
    );
}