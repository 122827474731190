import DefaultTheme from 'assets/themes/default';
const VariationTheme3 =  JSON.parse(JSON.stringify(DefaultTheme));

VariationTheme3.palette.primary.main='#05386B'
delete VariationTheme3.palette.primary.dark;
VariationTheme3.palette.primary.contrastText = '#EDF5E1';
VariationTheme3.components.MuiCssBaseline.styleOverrides.body.backgroundColor='#5CDB95';
delete VariationTheme3.header.bgColor;
VariationTheme3.header.color = 'white';

export default VariationTheme3;
