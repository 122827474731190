import {useParams} from "react-router-dom";
import {CreatePostBody} from "components/posts/create/CreatePostBody";
import * as React from "react";
 
function CreatePost(props)
{
    const {community_handle} = useParams();
    return (
        <>
            <CreatePostBody handle={community_handle} theme={props.theme}/>
        </>
    );
}

export {CreatePost}