import {AppBar, CssBaseline, Link, Toolbar, Typography, Box} from "@mui/material";
import {useAuth} from "services/firebase/AuthUserProvider"
import SearchInput from "components/header/SearchInput";
import UserDrawer from "components/header/UserDrawer";
import bellIcon from "assets/img/icons/bell.svg";
import bellNotificationIcon from "assets/img/icons/bell-notification.svg";
import messagesIcon from "assets/img/icons/messages.svg";
import messagesNotificationIcon from "assets/img/icons/messages-notification.svg";
import {useDispatch, useSelector} from "react-redux";
import {setUserDrawerOpen, setNotificationsOpen} from "services/redux/actions/systemActions";
import {useRef} from "react";
import NotificationPopup from "components/header/NotificationPopup";
import {USER_INFO_STATE} from "../utils/consts/userConsts";
import shareIcon from "../assets/img/icons/share-icon.svg";

export default function Header(props) 
{
    const dispatcher = useDispatch();
    const systemData = useSelector(state => state.systemData);
    const notifRef = useRef(null);
    
    let header = props.theme.header;
    const {authUser, loading} = useAuth();
    return(
        <>
            <UserDrawer/>
            <NotificationPopup buttonRef={notifRef}/>
            <CssBaseline/>
            <AppBar id='appbar-header' position = "fixed" sx ={{color:header.color,background:header.bgColor, boxShadow: header.boxShadow}}>
                <Toolbar sx={{width:'100%'}}>
                    <Box sx={{textAlign:'left', width:'50%'}}>
                        <Link href='/' align='left' underline='none' variant={header.logoVariant}  sx={{color:header.color, fontFamily:header.logoFontFamily, marginLeft: '20px'}}>
                            Community Feed
                        </Link>
                    </Box>
                    <Box sx={{width:'25%'}}>
                        <SearchInput theme ={props.theme}/>
                    </Box>
                    <Box sx={{display: systemData.user.state === USER_INFO_STATE.LOGGED_IN?'flex':'none', alignItems:'center', flexDirection:'row', 
                        justifyContent: 'space-between', rowGap:'0px 15%',
                        maxWidth:'20%', minWidth:'200px',  margin:'0'}}>
                        <Box sx={{width:'23.94px', height:'20px', maskRepeat:'no-repeat',
                            marginLeft:'auto', maskImage:`url(${messagesIcon})`, maskSize:'contain',
                            backgroundColor:header.color}}/>
                        <Box ref={notifRef} sx={{width:'21px', height:'26px', maskRepeat:'no-repeat',
                            marginLeft:'auto', maskImage:`url(${bellIcon})`, maskSize:'contain',
                            backgroundColor:header.color, position:'relative', top:'2px'}}
                             onClick={()=>dispatcher(setNotificationsOpen(true))}/>
                        <img onClick={handleProfileIconPressed} alt = '' 
                             src={authUser && authUser.avatar_url? authUser.avatar_url : ''} style ={{display:'inline-block',
                            borderRadius:'50%', width:'45px', height:'45px',
                            lineHeight:'45px', padding:'0px', objectFit:'cover', 
                            boxShadow:header.avatarBoxShadow, marginLeft:'auto'}}/>
                        <Typography alignItems="center" sx={{fontSize:'15px', fontWeight:'600', 
                            lineHeight:'15px', fontFamily: props.theme.fonts.font2,
                            display:'inline-block', height:'100%', marginLeft:'auto', position:'relative'}}>
                            {authUser? authUser.username : ''}
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: systemData.user.state === USER_INFO_STATE.LOGGED_OUT?'flex':'none',
                        justifyContent:'right',
                        minWidth:'25%'}}>
                        <Typography>
                        <Link sx={{width:'100%', color:'white', textAlign:'right', fontFamily:header.signInFont, fontWeight:'400'}} underline='none' href='/login'> Log In </Link>/
                        <Link sx={{width:'100%', color:'white', textAlign:'right', fontFamily:header.signInFont, fontWeight:'400'}} underline='none' href='/signup'> Sign Up </Link>
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    )
    
    function handleProfileIconPressed()
    {
        dispatcher(setUserDrawerOpen(!systemData.userDrawerOpen));
    }
}