import { SET_SECTION_ID, SET_COMMUNITY_INFO,
    SET_COMMUNITY_LOADING, SET_POSTS_LOADING, SET_COMMUNITY_MEMBERSHIP, SET_NEW_POSTS, UPDATE_POST} from "../reduxConsts";

const initialState ={
    posts: [],
    section_id: -1,
    triedFetchingPost: false,
}

function communityReducer(state = initialState, action)
{
    if(action.type === SET_COMMUNITY_INFO)
    {
        return Object.assign({}, state, action.payload);
    }
    else if(action.type === SET_COMMUNITY_LOADING)
    {
        return Object.assign({}, state, action.payload);
    }
    else if(action.type === SET_POSTS_LOADING)
    {
        return Object.assign({}, state, action.payload);
    }
    else if(action.type === SET_COMMUNITY_MEMBERSHIP)
    {
        return Object.assign({}, state, action.payload);
    }
    else if(action.type === SET_SECTION_ID)
    {
        return Object.assign({}, state, action.payload);
    }
    else if(action.type === UPDATE_POST)
    {
        let newState = Object.assign({}, state);
        const post = action.payload.post;
        if(!post)
        {
            return state;
        }
        for(let i = 0; i < newState.posts.length; i++)
        {
            const p = newState.posts[i];
            if(p.id === post.id)
            {
                newState.posts[i] = post;
                break;
            }
        }
        return newState;
    }
    else if(action.type === SET_NEW_POSTS)
    {
        if(action.payload.replace)
        {
            let newState = Object.assign({}, state);
            newState.posts = action.payload.posts;
            newState.triedFetchingPost = true;
            return newState;
        }
        var existingPosts = state.posts;
        var newPosts = action.payload.posts;
        const idSet = new Set();
        existingPosts.forEach(p => idSet.add(p.id));
        newPosts.forEach(p => {
           if(!idSet.has(p.id))
           {
               existingPosts.push(p);
           }
        });
        let newState = Object.assign({}, state);
        newState.posts = existingPosts;
        newState.triedFetchingPost = true;
        return newState;
    }
    return state;
}

export default communityReducer;